import classes from './SlickCarousel.module.scss';
import Slider from "react-slick";
// import  useScrollContainer  from 'react-indiana-drag-scroll';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import slidePic1 from "../../../FILES/Images/Cadru1.webp"
import slidePic2 from "../../../FILES/Images/Cadru2.webp"
import slidePic3 from "../../../FILES/Images/Cadru3.webp"
import slidePic4 from "../../../FILES/Images/Cadru4.webp"
import slidePic5 from "../../../FILES/Images/Cadru5.webp"
import slidePic6 from "../../../FILES/Images/Cadru6.webp"
import slidePic7 from "../../../FILES/Images/Cadru7.webp"

// function Arrow(props) {
//     const { className, style, onClick } = props;
//     return (
//       <div
//         className={className}
//         style={{ ...style, display: "block", background: "red" }}
//         onClick={onClick}
//       />
//     ); 
//   }

  

function SlickCarousel(props){ 
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        autoplaySpeed: 4000,
        cssEase: "linear",
        focusOnSelect: true,
        pauseOnHover: false,
        // nextArrow: <Arrow />,
        // prevArrow: <Arrow />
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
    };

    return (
        <div className={classes.slideshowContainer}>
            <Slider {...settings}>
                <div className={classes.pictureContainer}>
                    <img src={slidePic1} className={classes.pic} alt={'application screenshot'}/>
                </div>
                <div className={classes.pictureContainer}>
                    <img src={slidePic2} className={classes.pic} alt={'application screenshot'}/>
                </div>
                <div className={classes.pictureContainer}>
                    <img src={slidePic3} className={classes.pic} alt={'application screenshot'}/>
                </div>
                <div className={classes.pictureContainer}>
                    <img src={slidePic4} className={classes.pic} alt={'application screenshot'}/>
                </div>
                <div className={classes.pictureContainer}>
                    <img src={slidePic5} className={classes.pic} alt={'application screenshot'}/>
                </div>  
                <div className={classes.pictureContainer}>
                    <img src={slidePic6} className={classes.pic} alt={'application screenshot'}/>
                </div> 
                <div className={classes.pictureContainer}>
                    <img src={slidePic7} className={classes.pic} alt={'application screenshot'}/>
                </div>                                               
            </Slider>
        </div>
    );
  
}
export default SlickCarousel;

import classes from './AppSlideshow.module.scss';

import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import SectionCard from '../../../../COMPONENTS/UI/Cards/SectionCard';
import SlickCarousel from '../../../../COMPONENTS/UI/Carousel/SlickCarousel';
import colors from '../../../../UTILS/colors';

function AppSlideshow(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard backgroundColor={colors.section}  flexDirection={`column`} id={props.id}>      
                <h1>{text[34]}</h1>
                <p className={classes.p}>{text[35]}</p>    
                <SlickCarousel/>   
    </SectionCard> 
);
} 

export default AppSlideshow;
const blog1TextTranslations= {
    0: {
         en:``,
         ro:``
      },
    1: {
         en:``,
         ro:`Ce activități sunt potrivite pentru un copil timid?`
      },
    2: {
         en:``,
         ro:`"Copilul meu este mai retras și preferă să se joace singur."`
      },
    3: {
         en:``,
         ro:`"Are nevoie de ceva timp să se obișnuiască, dacă venim într-un loc necunoscut. Se ascunde după mine dacă în preajmă sunt persoanele necunoscute."`
      },
    4: {
         en:``,
         ro:``
      },
    5: {
         en:``,
         ro:`"Nu privește în ochi persoana care îi vorbește. Evită să răspundă la întrebările persoanelor necunoscute."`
      },
    6: {
         en:``,
         ro:``
      },
    7: {
         en:``,
         ro:`Îți sună cunoscut? Cum poți să-ți ajuți copilul să lupte cu timiditatea?`
      },
    8: {
         en:``,
         ro:`În primul rând, trebuie să înțelegi de ce este important să faci acest lucru cât mai devreme posibil. Adevărul este că timiditatea poate avea consecințe devastatoare:`
      },
    9: {
         en:``,
         ro:`- îl va împiedica să cunoască oameni noi și să-și facă prieteni;`
      },
    10: {
         en:``,
         ro:`- va ezita să-și exprime opinia și să-și apere drepturile;`
      },
    11: {
         en:``,
         ro:`- va împiedica alte persoane să-i evalueze pozitiv demnitatea;`
      },
    12: {
         en:``,
         ro:`- va genera sentimente de singurătate, anxietate și depresie.`
      },
    13: {
         en:``,
         ro:`Vestea bună este că POȚI să-ți ajuți copilul să facă față timidității și să relaționeze mai ușor cu cei din jur, descoperindu-i talentele care îl fac să se simtă special!`
      },
    14: {
         en:``,
         ro:`Încearcă diverse tipuri de activități - cu cât un copil face mai multe lucruri, cu atât va avea mai multe de împărțit cu alții și îi va fi mai ușor să interacționeze cu ei.`
      },
    15: {
         en:``,
         ro:`Printre cele mai populare activități recomandate de psihologi pentru copiii timizi sunt:`
      },
    16: {
         en:``,
         ro:`Lecții de muzică`
      },
    17: {
         en:``,
         ro:`Pentru început acestea se fac doar cu profesorul, apoi copilul poate să fie implicat într-un ansamblu instrumental sau într-un cor, iar pe măsură ce încrederea în sine crește, copilul poate să fie încurajat să cânte solo.`
      },
    18: {
         en:``,
         ro:`Cursuri de actorie`
      },
    19: {
         en:``,
         ro:`Aici copilul învață să vorbească și să se exprime, iar faptul că pe scenă se ascunde de după costumul eroului său îl va ajuta să se simtă mai sigur pe sine.`
      },
    20: {
         en:``,
         ro:`Pictură`
      },
    21: {
         en:``,
         ro:`Un copil care nu-și exprimă atât de liber sentimentele are nevoie de activități care îl pot ajuta să le externalizeze. Cursurile de pictură sunt perfecte în acest sens. Important este să organizezi și micile expoziții cu lucrările copilului, încât să vadă că talentul său este apreciat.
`
      },
    22: {
         en:``,
         ro:`Dacă dorești să identifici mai multe activități recomandate pentru copii timizi, te invităm să-ți instalezi aplicația Parent’s Advisor.`
      },
    23: {
         en:``,
         ro:``
      },
  
};
export default blog1TextTranslations;
import classes from './Feature.module.scss';

function Feature({classNameColor, icon: SvgIcon, ...props}) {

return (
    <div className={classes.itemContainer}>
        <div className={classes.iconContainer}>
            <SvgIcon className={`${classes.svgIcon} ${classNameColor}`} style={{width: `${props.iconSize}px`}}/>
            {/* style={{width: `${props.iconSize}px`}} */}
        </div>
        <div className={classes.textContainer}>
            <h3>{props.titleText}</h3>
            <p>{props.paragraphText}</p>
        </div>        

    </div>
);
}
  
export default Feature;
import classes from './Article.module.scss';
import { Link } from 'react-router-dom';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText'; 
import UserSvgIcon from '../../../../COMPONENTS/SVG/Icons/UserSvgIcon';
import Calendar1SvgIcon from '../../../../COMPONENTS/SVG/Icons/Calendar1SvgIcon';
function Articol(props){
    const link = useGetTranslatedText('links_TextTranslations.js');
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <div className={classes.container} >
        <div className={classes.pictureBox}>
            <img src={props.picture} className={classes.pic} alt='child'/>
            </div> 
          
        <div className={classes.dateContainer}> 
            <div className={classes.dateLeft}> 
                <UserSvgIcon className={classes.svgIcon}/>
                <span>Parent´s Advisor</span>
            </div>  
            <div className={classes.dateRight}> 
                <Calendar1SvgIcon className={classes.svgIcon}/>
                <span>{props.date}</span>
           </div>  
        </div>   
        <h2 className={classes.h2} >{props.title}</h2>
        <p className={classes.p}>{props.text}</p>   
        <Link to={`/${link[props.linkPos]}`}>
            <div className={classes.readMore}> {text[39]}</div>
        </Link> 
    </div>
);
}


export default Articol;    

const colors= {
    section: `rgb(251, 251, 251)` ,    
       
    };
    
    export default colors;
    
 
    
    
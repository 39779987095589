import IconSVG from '../IconSVG';


function VideoTimerSvgIcon(props) {  
    // const color = props.c || 'black';
    const lineWidth=2;
    const svgWidth=58;
    const svgHeight=58;    
    const d1="M45,32.5c-6.617,0-12,5.383-12,12s5.383,12,12,12s12-5.383,12-12S51.617,32.5,45,32.5z M45,54.5c-5.514,0-10-4.486-10-10s4.486-10,10-10s10,4.486,10,10S50.514,54.5,45,54.5z";
    const d2="M51,43.5h-5v-5c0-0.553-0.447-1-1-1s-1,0.447-1,1v6c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1S51.553,43.5,51,43.5z";
    const d3="M57,1.5H1c-0.552,0-1,0.447-1,1v44c0,0.553,0.448,1,1,1h29.302C30.105,46.53,30,45.527,30,44.5c0-8.271,6.729-15,15-15\n" +
    "c1.027,0,2.03,0.105,3,0.302V25.5h8v8.836c0.763,0.825,1.436,1.732,2,2.712V2.5C58,1.947,57.552,1.5,57,1.5z M10,45.5H2v-9h8V45.5z\n" +
    " M10,34.5H2v-9h8V34.5z M10,23.5H2v-9h8V23.5z M10,12.5H2v-9h8V12.5z M36.537,25.344l-11,7C25.374,32.447,25.187,32.5,25,32.5\n" +
    "c-0.166,0-0.331-0.041-0.481-0.123C24.199,32.201,24,31.865,24,31.5v-14c0-0.365,0.199-0.701,0.519-0.877\n" +
    "c0.32-0.175,0.71-0.162,1.019,0.033l11,7C36.825,23.84,37,24.158,37,24.5S36.825,25.16,36.537,25.344z M56,23.5h-8v-9h8V23.5z\n" +
    " M56,12.5h-8v-9h8V12.5z";



    return (
        <IconSVG 
        id={'videoTimer'}
            svgWidth={svgWidth} 
            svgHeight={svgHeight} 
            lineWidth={lineWidth}        
            d1={d1} 
            d2={d2} 
            d3={d3}
            className={props.className}
            style={{...props.style}}
        />
    );
};

export default VideoTimerSvgIcon;
import IconSVG from '../IconSVG';


function Library3SvgIcon(props) {  
    // const color = props.c || 'black';
    const lineWidth=0;
    const svgWidth=512;
    const svgHeight=512;    
    const d1="M490.667,369.47h-7.172c-0.031-1.704-0.264-3.43-0.725-5.151L398.994,51.68c-3.05-11.381-14.748-18.135-26.129-15.084\n" +
    "l-78.165,20.949c-11.38,3.05-18.133,14.748-15.084,26.128l8.054,30.056c-1.75-0.167-3.52-0.269-5.316-0.269H64.006\n" +
    "c-16.075,0-26.375,17.105-18.855,31.313l8.683,16.405c5.251,9.926,5.251,22.638-0.002,32.567l-2.672,5.048h-8.487\n" +
    "c-16.075,0-26.375,17.105-18.855,31.313l8.683,16.405c5.251,9.926,5.251,22.638-0.002,32.567l-8.681,16.402\n" +
    "c-7.52,14.208,2.78,31.313,18.855,31.313h8.487l2.673,5.051c5.251,9.926,5.251,22.638-0.002,32.567l-2.677,5.059H21.333\n" +
    "C9.551,369.47,0,379.022,0,390.804v64c0,11.782,9.551,21.333,21.333,21.333h469.333c11.782,0,21.333-9.551,21.333-21.333v-64\n" +
    "C512,379.022,502.449,369.47,490.667,369.47z M363.303,83.331l72.733,271.427l-36.953,9.904L326.35,93.235L363.303,83.331z\n" +
    " M76.167,241.46H261.02c8.385,0,16.32,8.991,16.32,21.333c0,12.342-7.935,21.333-16.32,21.333H76.167\n" +
    "C80.084,270.235,80.084,255.35,76.167,241.46z M97.5,156.126h184.853c8.385,0,16.32,8.991,16.32,21.333\n" +
    "c0,12.342-7.935,21.333-16.32,21.333H261.02H97.5C101.417,184.902,101.417,170.016,97.5,156.126z M261.02,326.793h21.333\n" +
    "c8.385,0,16.32,8.991,16.32,21.333s-7.935,21.333-16.32,21.333H97.5c3.917-13.891,3.917-28.777,0-42.667H261.02z M312.887,293.306\n" +
    "c4.552-9.121,7.12-19.536,7.12-30.513c0-10.976-2.568-21.392-7.12-30.513c1.896-1.245,3.717-2.604,5.461-4.064l37.851,141.254\n" +
    "h-18.23c2.182-6.705,3.371-13.894,3.371-21.344C341.34,325.139,330.092,304.604,312.887,293.306z M469.333,433.47H42.667v-21.333\n" +
    "h426.667V433.47z";

    return (
        <IconSVG 
        id={"library3"}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            lineWidth={lineWidth}
            d1={d1}             
            className={props.className}
            // style={{...props.style}}
            style={{...props.style}}
        />
    );
};

export default Library3SvgIcon;
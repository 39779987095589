import classes from './Register.module.scss';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import SectionCard from '../../../../COMPONENTS/UI/Cards/SectionCard';
import shape1 from '../../../../FILES/Images/shape1.png';
import shape2 from '../../../../FILES/Images/shape2.png';
import shape3 from '../../../../FILES/Images/shape3.png';

function Register(props) {
    const text = useGetTranslatedText('homePage_TextTranslations.js');
    return (
        <SectionCard backgroundColor={'rgb(245, 245, 245)'} textAlign={`center`} id={props.id}>
             <div className={classes.blueRectangle}/>
            <div className={classes.mainContainer}>
               
                <div className={classes.topLeft}>
                    <img src={shape2} className={classes.shape} alt={'Abstract shape decoration'}/>
                </div> 
                <div className={classes.topRight}>
                    <img src={shape3} className={classes.shape} alt={'Abstract shape decoration'} />
                </div> 
                <div className={classes.bottomLeft}>
                    <img src={shape1} className={classes.shape} alt={'Abstract shape decoration'} />
                </div> 
                <div className={classes.textContainer}>
                    <h2>{text[44]}</h2>
                </div> 
                {/* <div className={classes.inputContainer}>
                </div>  */}
            </div>

           
        </SectionCard>
);
}
  
  export default Register;
import {ScrollTo, ScrollArea} from "react-scroll-to";

import Assistant from "./sections/Assistant/Assistant";
import Reasons from "./sections/Reasons/Reasons";
import AboutUs from "./sections/AboutUs/AboutUs";
import Steps from "./sections/Steps/Steps";
import AppSlideshow from "./sections/AppSlideshow/AppSlideshow";
import Articles from "./sections/Articles/Articles";
import Register from "./sections/Register/Register";
import SocialMedia from "./sections/SocialMedia/SocialMedia";
import Footer from '../../COMPONENTS/UI/Footer/Footer';
import Features from "./sections/Features/Features";
import { useEffect } from "react";

function HomePage(props) {
    useEffect(()=>{
        props.onLoad('home');
    },[props]) 

    return (
        <ScrollTo>
            {({scroll}) => (
                <ScrollArea>
                    <div > 
                        <div style={{height:'100px'}}></div>
                        <Assistant id={'assistant'}/>
                        <AboutUs id={'aboutUs'}/>
                        <Steps id={'steps'}/>
                        <Reasons id={'reasons'}/>
                        <Features id={'features'}/>
                        <AppSlideshow id={'appSlideShow'}/>
                        <Articles id={'articles'}/>
                        <Register id={'register'}/>
                        <SocialMedia id={'socialMedia'}/>
                        <Footer/>
                    </div>
                 </ScrollArea>
             )}
         </ScrollTo>
    );
}

export default HomePage;

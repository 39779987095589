
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';

function PB (props){
    const pageText = useGetTranslatedText('termsPage_TextTranslations.js');

    return (
        <p><b>{pageText[props.t]}</b></p>
    );
};

export default PB;
import IconSVG from '../IconSVG';

function Facebook2SvgIcon(props) {  
    // const color = props.c || 'black';
    const lineWidth=0;
    const svgWidth=32;
    const svgHeight=32;
    const d1="M 19.253906 2 C 15.311906 2 13 4.0821719 13 8.8261719 L 13 13 L 8 13 L 8 18 L 13 18 L 13 30 L 18 30 L 18 18 L 22 18 L 23 13 L 18 13 L 18 9.671875 C 18 7.884875 18.582766 7 20.259766 7 L 23 7 L 23 2.2050781 C 22.526 2.1410781 21.144906 2 19.253906 2 z";

    
    
    return (
        <IconSVG 
        id={"facebook2"}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            lineWidth={lineWidth}
            d1={d1} 

 
            className={props.className}
            style={{...props.style}}
        />
    );
};

export default Facebook2SvgIcon;
import classes from './Steps.module.scss';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import SectionCard from '../../../../COMPONENTS/UI/Cards/SectionCard';
import Step from './Step/Step';

function Steps(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard flexDirection={`column`} id={props.id}>
            <div className={classes.containerText}>                  
                <h1><span>{text[24]}</span> <span  style={{color:`rgb(66,82,253)`}}>{text[68]}</span></h1>
            </div>
            <div className={classes.containerPas}>                  
                <Step step={text[25]} title={text[26]} text={text[27]} backColor={`rgba(233, 242, 244, 1)`}/>
                <Step step={text[28]} title={text[29]} text={text[30]} backColor={`rgba(234, 228, 239, 1)`}/>
                <Step step={text[31]} title={text[32]} text={text[33]} backColor={`rgba(236, 231, 224, 1)`}/>
            </div>
    </SectionCard> 

);
}


export default Steps;

import Background from '../../COMPONENTS/UI/Background/Background';
import Footer from '../../COMPONENTS/UI/Footer/Footer';
import { useEffect } from 'react';
import BlogActivitati from './components/BlogActivitati';

function CeActivitatiSuntPotrivite(props) {
    
    useEffect(()=>{
        props.onLoad('blog');
    },[props])  
 
  return (
    <Background>        
        <BlogActivitati/>
        <Footer/>
    </Background>
  );
}

export default CeActivitatiSuntPotrivite;

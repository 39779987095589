import classes from './Reason.module.scss';

function Reason({classNameColor, icon: SvgIcon, ...props}){

return (
    <div className={classes.container} style={{backgroundColor:props.backColor}}>
        <div className={classes.pictureBox}>
            <div className={classes.innerBox} style={{backgroundColor:props.backColor}}/>
            <SvgIcon className={`${classes.svgIcon} ${classNameColor}`}/>
        </div>        
        <h2 className={classes.h3} >{props.title}</h2>
        <p className={classes.p}>{props.text}</p>    
    </div>
);
}


export default Reason;    

// import classes from './HomeNavOption.module.scss';
import classes from './TopSimpleNavigationMenu.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { storeGeneral_Actions } from '../../../../STORE/storeGeneral';
import { useNavigate } from 'react-router-dom';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';

function HomeNavOption (props) {
    const text = useGetTranslatedText('homePage_TextTranslations.js');  
    const activeLang = useSelector((state) => state.generalStore.selectedLanguage); 
    const dispatch = useDispatch(); 
    const navigate  = useNavigate();

    const onChangeLang_Handler = (lang) =>{
        dispatch( storeGeneral_Actions.changeLang({lang:lang}));        
    };

return (
    <div className={classes.navListContainer}>
        <ul className={classes.ulMainNav} > 
            <li className={classes.liMainNav} onClick={() => navigate('/')}>{text[1]}</li>
            <li className={classes.liMainNav}>
                <span className={activeLang === 'en' ? classes.langActiveColor :''}  onClick={() => onChangeLang_Handler('en')}>ENG </span>
                <span> / </span>
                <span className={activeLang === 'ro' ? classes.langActiveColor : ''}  onClick={() => onChangeLang_Handler('ro')}> RO</span>
            </li>
        </ul>     
    </div>
);
};

export default HomeNavOption;

import { Fragment } from 'react';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import { scrollToSection } from '../../../../UTILS/scrollToSection';

function NavOptions (props) {
    const text = useGetTranslatedText('homePage_TextTranslations.js');  
    const onClick_Handler = (section) => {
        scrollToSection(section);
        props.onClose();
    }
    return (
       <Fragment> 
            <li className={props.className} onClick={() =>onClick_Handler('assistant')}>{text[1]}</li>
            <li className={props.className} onClick={() =>onClick_Handler('aboutUs')}>{text[2]}</li>
            <li className={props.className} onClick={() =>onClick_Handler('steps')}>{text[3]}</li>
            <li className={props.className} onClick={() =>onClick_Handler('reasons')}>{text[4]}</li>
            <li className={props.className} onClick={() =>onClick_Handler('articles')}>{text[5]}</li>
            <li className={props.className} onClick={() =>onClick_Handler('features')}>{text[7]}</li>
        </Fragment>
    );
};

export default NavOptions;

const generalTextTranslations= {
    0: {
         en:``,
         ro:``
      },
    0: {
         en:``,
         ro:``
      },
    0: {
         en:``,
         ro:``
      },
    1: {
         en:`Parent's Advisor Privacy Policy`,
         ro:`Politica de Confidențialitate Parents Advisor`
      },
    2: {
         en:`Updated: December 1, 2023`,
         ro:`Actualizare: 1.12.2023`
      },
    3: {
         en:`1. About This Privacy Policy`,
         ro:`1. Despre această Politică de Confidențialitate`
      },
    4: {
         en:`Thank you for choosing Parent's Advisor, our mobile application designed to serve as a virtual psychologist for parents ("The Application"). This Privacy Policy explains how we collect, use, and protect your personal information when you use the Application. By using the Application, you agree to the practices described in this Privacy Policy. If you do not agree with the practices described below, please do not use the Application.
`,
         ro:`Vă mulțumim că ați ales Parent's Advisor, aplicația noastră mobilă destinată să servească ca un psiholog virtual pentru părinți ("Aplicația"). Această Politică de Confidențialitate explică
modul în care colectăm, utilizăm și protejăm informațiile dvs. personale atunci când utilizați Aplicația. Prin utilizarea Aplicației, sunteți de acord cu practicile descrise în această Politică de Confidențialitate. Dacă nu sunteți de acord cu practicile descrise mai jos, vă rugăm să nu utilizați Aplicația.
`
      },
    5: {
         en:`2. Personal Information Processed by Parent's Advisor`,
         ro:`2. Informații Personale Procesate de Parent's Advisor`
      },
    6: {
         en:`We collect and process personal information based on how you interact with Parent's Advisor and your account settings. This information includes:`,
         ro:`Colectăm și procesăm informații personale în funcție de modul în care interacționați cu Parent's Advisor și setările contului. Aceste informații includ:
`
      },
    7: {
         en:`● Information Provided by You: We use the minimum necessary data, strictly required for the operation of our algorithms: Name, email address, child's name, and age.`,
         ro:`● Informații Furnizate de dvs.: Folosim cât mai puține posibile date, strict necesare pentru funcționarea algoritmilor : Nume, adresă de e-mail, numele copilului și vârsta.`
      },
    8: {
         en:`● Communication Data: Information shared through interaction with Parent's Advisor services, such as feedback and questions asked to the application.`,
         ro:`● Date de Comunicare: Informații partajate prin interacțiunea cu serviciile Parent's Advisor, cum ar fi feedback-ul și întrebările puse aplicației.
`
      },
    9: {
         en:`3. Use of Personal Information
`,
         ro:`3. Utilizarea Informațiilor Personale`
      },
    10: {
         en:`We use the collected information to provide and improve Parent's Advisor services, to communicate with you, and to respond to assistance requests. We implement security measures to protect personal data against unauthorized access and illegal use.
`,
         ro:`Utilizăm informațiile colectate pentru a furniza și îmbunătăți serviciile Parent Advisor, pentru a comunica cu dvs. și pentru a răspunde solicitărilor de asistență. Implementăm măsuri de securitate pentru a proteja datele personale împotriva accesului neautorizat și a utilizării ilegale.
`
      },
    11: {
         en:`Automatically Collected Information:`,
         ro:`Ce informații pe care le Colectăm Automat:`
      },
    12: {
         en:`We respect data protection legislation and consider certain information as personal according to applicable laws. We automatically collect different types of data during the use of our service, including:`,
         ro:`Respectăm legislația privind protecția datelor și considerăm anumite informații ca fiind personale conform legilor aplicabile. Colectăm automat diferite tipuri de date în timpul utilizării serviciului nostru, inclusiv:`
      },
    13: {
         en:`3.1. Device and Browser Information: Device type, screen resolution, name and version of the operating system, language, type and version of the internet browser.`,
         ro:`3.1. Informații despre Dispozitiv și Browser: tipul dispozitivului, rezoluția ecranului, numele și versiunea sistemului de operare, limba, tipul și versiunea browserului de internet.
`
      },
    14: {
         en:`3.2. Usage Data: Your activities in the application, service performance, and data on application usage frequency.
`,
         ro:`3.2. Date de Utilizare: activitățile dvs. în aplicație, performanța serviciilor și date privind frecvența utilizării aplicației.
`
      },
    15: {
         en:`3.3. Log Data: Records of access to our web servers and activity in services.
`,
         ro:`3.3. Date de Jurnal: înregistrări ale accesărilor pe serverele noastre web și activitatea în servicii.`
      },
    16: {
         en:`3.4. Cookies and Tracking Technologies: We use these technologies to understand how our service is used and to improve the user experience.
`,
         ro:`3.4. Cookie-uri și Tehnologii de Urmărire: folosim aceste tehnologii pentru a înțelege cum este utilizat serviciul nostru și pentru a îmbunătăți experiența utilizatorului.
`
      },
    17: {
         en:`4. Your Data Protection Rights with Parent's Advisor`,
         ro:`4. Drepturile Dumneavoastră de Protecție a Datelor cu Parent's Advisor`
      },
    18: {
         en:`4.1. Know Your Rights
`,
         ro:`4.1. Cunoașteți-vă Drepturile`
      },
    19: {
         en:`Depending on the jurisdiction in which you live, you may have the following data protection rights in relation to Parent's Advisor:
`,
         ro:`În funcție de jurisdicția în care locuiți, puteți avea următoarele drepturi de protecție a datelor în legătură cu Parent's Advisor:
`
      },
    20: {
         en:`● Access, correct, update, transfer, delete, restrict, or object to the processing of your personal information by Parent's Advisor.
`,
         ro:`● Accesați, corectați, actualizați, transferați, ștergeți, restrângeți sau vă opuneți prelucrării informațiilor personale de către Parent's Advisor.`
      },
    21: {
         en:`● Withdraw consent for consent-based processing, without affecting the legality of processing carried out before withdrawal.
`,
         ro:`● Retragerea consimțământului pentru prelucrarea bazată pe consimțământ, fără a afecta legalitatea prelucrării efectuate înainte de retragere.
`
      },
    22: {
         en:`Exercising Your Rights`,
         ro:`Exercitarea Drepturilor`
      },
    23: {
         en:`To exercise your rights, you can contact us at parentsadvisor.app@gmail.com. We will respond to requests to exercise data protection rights in accordance with applicable legislation. We may ask you to verify your identity to efficiently process your request.
`,
         ro:`Pentru a vă exercita drepturile, ne puteți contacta la parentsadvisor.app@gmail.com. Vom răspunde la solicitările de exercitare a drepturilor de protecție a datelor în conformitate cu
legislația aplicabilă. Vă putem solicita să vă verificați identitatea pentru a procesa cererea dvs. eficient.
`
      },
    24: {
         en:`4.2. Sharing Personal Information`,
         ro:`4.2. Partajarea Informațiilor Personale`
      },
    25: {
         en:`Parent's Advisor does not share personal information with third parties, except in the limited cases described below:`,
         ro:`Parent's Advisor nu împărtășește informații personale cu terțe părți, cu excepția cazurilor limitate descrise mai jos:`
      },
    26: {
         en:`● Affiliates and Service Providers: May be shared with affiliated companies or service providers acting on our behalf.`,
         ro:`● Afiliați și Furnizori de Servicii: Pot fi partajate cu companii afiliate sau furnizori de servicii care prestează servicii în numele nostru.`
      },
    27: {
         en:`● Social Network Partners: For promoting Parent's Advisor services.`,
         ro:`● Parteneri Rețele Sociale: Pentru promovarea serviciilor Parent's Advisor.
`
      },
    28: {
         en:`● Legal and Governmental Authorities: In case of legal requirements or for the protection of legal rights.`,
         ro:`● Autorități Legale și Guvernamentale: În cazul unor cerințe legale sau pentru protecția drepturilor legale.
`
      },
    29: {
         en:`● Commercial Transactions: In case of a merger, sale, or other business transactions.`,
         ro:`● Tranzacții Comerciale: În cazul unei fuziuni, vânzări sau alte tranzacții comerciale.
`
      },
    30: {
         en:`● Other Parties with Your Explicit Consent: Situations where you have given explicit consent.`,
         ro:`● Alte Părți cu Acordul Dumneavoastră: Situații în care aveți acordul explicit.`
      },
    31: {
         en:`Responsibility for Data Confidentiality`,
         ro:`Responsabilitatea pentru Confidențialitatea Datelor`
      },
    32: {
         en:`Parent's Advisor is responsible for the confidentiality of data provided to service providers and partners, ensuring that they comply with our data protection and security standards.`,
         ro:`Parent's Advisor este responsabil pentru confidențialitatea datelor furnizate către furnizorii de servicii și parteneri, asigurându-se că aceștia respectă standardele noastre de protecție și securitate a datelor.
`
      },
    33: {
         en:``,
         ro:``
      },
    34: {
         en:``,
         ro:``
      },
    35: {
         en:`5. Data Security at Parent's Advisor`,
         ro:`5. Securitatea Datelor la Parent's Advisor`
      },
    36: {
         en:`Parent's Advisor implements a comprehensive security program designed to protect the security, confidentiality, and integrity of personal information. We implement appropriate technical and organizational measures to protect personal data against loss, misuse, unauthorized access, disclosure, alteration, and destruction.
`,
         ro:`Parent's Advisor implementează un program cuprinzător de securitate menit să protejeze securitatea, confidențialitatea și integritatea informațiilor personale. Implementăm măsuri
tehnice și organizatorice adecvate pentru a proteja datele personale împotriva pierderii, utilizării greșite, accesului neautorizat, dezvăluirii, modificării și distrugerii.`
      },
    37: {
         en:`5.1. International Data Transfers`,
         ro:`5.1. Transferuri Internaționale de Date`
      },
    38: {
         en:`Data collected by Parent's Advisor may be transferred, processed, and stored outside of the users' country of residence, in countries where we operate or where our service providers are located. We ensure that any such transfer complies with applicable law.`,
         ro:`Datele colectate de Parent's Advisor pot fi transferate, procesate și stocate în afara țării de reședință a utilizatorilor, în țările unde operăm sau unde se află furnizorii noștri de servicii. Ne asigurăm că orice astfel de transfer respectă legislația aplicabilă.`
      },
    39: {
         en:`5.2. Retention of Personal Information`,
         ro:`5.2. Păstrarea Informațiilor Personale
`
      },
    40: {
         en:`Parent's Advisor does not retain personal information longer than necessary for the purposes for which it was collected. The retention period depends on legal or contractual needs and the reasonable expectations of users. When personal data are no longer needed, they are deleted or anonymized.`,
         ro:`Parent's Advisor nu păstrează informațiile personale mai mult decât este necesar pentru scopurile pentru care au fost colectate. Durata de păstrare depinde de nevoia legală sau
contractuală și așteptările rezonabile ale utilizatorilor. Atunci când nu mai este necesară păstrarea datelor personale, acestea sunt șterse sau anonimizate.
`
      },
    41: {
         en:`5.3. Users in Certain Jurisdictions`,
         ro:`5.3. Utilizatorii din Anumite Jurisdicții
`
      },
    42: {
         en:`For users in certain jurisdictions, we provide additional details regarding the collection, use, and disclosure of personal information, in accordance with specific laws.
`,
         ro:`Pentru utilizatorii din anumite jurisdicții, oferim detalii suplimentare privind colectarea, utilizarea și dezvăluirea informațiilor personale, conform legilor specifice.`
      },
    43: {
         en:`5.4. Categories of Personal Information Collected and Processed by Parent's Advisor`,
         ro:`5.4. Categorii de Informații Personale Colectate și Procesate de Parent's Advisor`
      },
    44: {
         en:`● Personal Identifiers: Examples - Names, email addresses.`,
         ro:`● Identificatori Personal: Exemple - Nume, adrese de e-mail.`
      },
    45: {
         en:`● Personal Information: Examples - Contact information.`,
         ro:`● Informații Personale: Exemple - Informații de contact.`
      },
    46: {
         en:`● Protected Class Information: Examples - Age.`,
         ro:`● Informații de Clasă Protejate: Exemple - Vârstă.`
      },
    47: {
         en:`● Commercial Information: Examples - Transaction information.`,
         ro:`● Informații Comerciale: Exemple - Informații despre tranzacții.`
      },
    48: {
         en:`● Internet or Network Activity Information: Examples - Browsing history.`,
         ro:`● Informații despre Activitatea pe Internet sau în Rețea: Exemple - Istoric de navigare.`
      },
    49: {
         en:`● Geolocation Data: Examples - City-level information.`,
         ro:`● Datele de Geolocalizare: Exemple - Informații la nivel de oraș.`
      },
    50: {
         en:`● Audio, Electronic, Visual Information: Examples - User-uploaded photographs.`,
         ro:`● Informații Audio, Electronice, Vizuale: Exemple - Fotografii încărcate de utilizatori.`
      },
    51: {
         en:`5.5 Disclosure of Information to Third Parties`,
         ro:`5.5 Dezvăluirea Informațiilor Către Terți`
      },
    52: {
         en:`Parent's Advisor may disclose categories of personal information to:`,
         ro:`Parent's Advisor poate dezvălui categoriile de informații personale către:`
      },
    53: {
         en:`● Affiliates and service providers for operational purposes.`,
         ro:`● Afiliați și prestatori de servicii pentru scopuri operaționale.`
      },
    54: {
         en:`● Public institutions for legal compliance and rights protection.`,
         ro:`● Instituții publice pentru conformitate legală și protecția drepturilor.`
      },
    55: {
         en:`● Social network providers for promoting Parent's Advisor services.`,
         ro:`● Furnizori de rețele sociale pentru promovarea serviciilor Parent's Advisor.`
      },
    56: {
         en:`● Potential buyers in commercial transactions.`,
         ro:`● Potențiali cumpărători în cazul tranzacțiilor comerciale.`
      },
    57: {
         en:`● Other third parties with the user's explicit consent.`,
         ro:`● Alte terțe părți cu acordul expres al utilizatorului.`
      },
    58: {
         en:``,
         ro:``
      },
    59: {
         en:``,
         ro:``
      },
    60: {
         en:`6. Rights and Requests within Parent's Advisor`,
         ro:`6. Drepturi și Cereri în cadrul Parent's Advisor
`
      },
    61: {
         en:`6.1 Exercising Your Rights`,
         ro:`6.1 Exercitarea Drepturilor Dumneavoastră`
      },
    62: {
         en:`According to applicable law, you have the right to make the following requests at Parent's Advisor:
`,
         ro:`Conform legislației aplicabile, aveți dreptul de a face următoarele solicitări la Parent's Advisor:`
      },
    63: {
         en:`● Access to Personal Information: Find out if we process your personal information and access these details.`,
         ro:`● Acces la Informații Personale: Aflați dacă procesăm informațiile dvs. personale și accesați aceste informații.`
      },
    64: {
         en:`● Correction of Inaccuracies: Correct inaccuracies in your personal information.`,
         ro:`● Corectarea Inexactităților: Rectificați inexactitățile din informațiile dvs. personale.`
      },
    65: {
         en:`● Data Deletion: Request the deletion of personal information.`,
         ro:`● Ștergerea Datelor: Solicitați ștergerea informațiilor personale.`
      },
    66: {
         en:`● Data Portability: Receive a copy of your personal information in a portable format.`,
         ro:`● Portabilitatea Datelor: Primiți o copie a informațiilor personale într-un format portabil.`
      },
    67: {
         en:`6.2 Specific Requests for Residents in Certain Jurisdictions`,
         ro:`6.2 Cereri Specifice pentru Rezidenți în Anumite Jurisdicții
`
      },
    68: {
         en:`Request categories of personal information collected, the purpose of collection, and categories of third parties to whom we have disclosed these details.`,
         ro:`Solicitați categoriile de informații personale colectate, scopul colectării și categoriile de terțe părți cărora le-am dezvăluit aceste informații.`
      },
    69: {
         en:`6.3 Non-Discrimination`,
         ro:`6.3 Non-Discriminare`
      },
    70: {
         en:`You have the right not to be discriminated against for exercising privacy rights.`,
         ro:`Aveți dreptul de a nu fi discriminat pentru exercitarea drepturilor de confidențialitate.
`
      },
    71: {
         en:`6.4 Process for Submitting Requests`,
         ro:`6.4 Procesul de Trimitere a Cererilor`
      },
    72: {
         en:`Contact us at parentsadvisor.app@gmail.com to make a request.`,
         ro:`Contactați-ne la parentsadvisor.app@gmail.com pentru a face o cerere.
`
      },
    73: {
         en:`6.5 Parent's Advisor's Role in Data Processing`,
         ro:`6.5 Rolul Parent's Advisor în Prelucrarea Datelor`
      },
    74: {
         en:`Parent's Advisor acts as a data processor, collecting personal information on behalf of our users.
`,
         ro:`Parent's Advisor acționează ca procesor de date, colectând informații personale în numele utilizatorilor.
`
      },
    75: {
         en:`6.6 Sale of Personal Information`,
         ro:`6.6 Vânzarea Informațiilor Personale`
      },
    76: {
         en:`Parent's Advisor does not sell personal information.`,
         ro:`Parent's Advisor nu vinde informații personale.`
      },
    77: {
         en:`6.7 Appeal Process`,
         ro:`6.7 Procesul de Apel`
      },
    78: {
         en:`Requests made by authorized agents will be processed in accordance with applicable law.`,
         ro:`Solicitările făcute de agenți autorizați vor fi procesate conform legislației aplicabile.`
      },
    79: {
         en:`6.8 Use of De-identified Information`,
         ro:`6.8 Utilizarea Informațiilor De-Identificate`
      },
    80: {
         en:`Parent's Advisor maintains and uses de-identified information only in a de-identified manner.`,
         ro:`Parent's Advisor menține și utilizează informațiile de-identificate numai într-un mod de-identificat.`
      },
    81: {
         en:`6.9 Do Not Track (DNT)`,
         ro:`6.9 Nu Urmăriți (Do Not Track)
`
      },
    82: {
         en:`Parent's Advisor respects "Do Not Track" settings in your browser.`,
         ro:`Parent's Advisor respectă setările "Nu urmăriți" din browserul dvs.`
      },
    83: {
         en:``,
         ro:``
      },
    84: {
         en:``,
         ro:``
      },
    85: {
         en:`7. Changes to the Privacy Policy`,
         ro:`7. Modificări ale Politicii de Confidențialitate`
      },
    86: {
         en:`Parent's Advisor reserves the right to modify this Privacy Policy.`,
         ro:`Parent's Advisor își rezervă dreptul de a modifica această Politică de Confidențialitate.
`
      },
    87: {
         en:`8. Collection of Personal Information from Children`,
         ro:`8. Colectarea Informațiilor Personale de la Copii`
      },
    88: {
         en:`Parent's Advisor does not collect personal information from children for commercial purposes.`,
         ro:`Parent's Advisor nu colectează informații personale de la copii în scopuri comerciale.`
      },
    89: {
         en:``,
         ro:``
      },
    90: {
         en:``,
         ro:``
      },
    91: {
         en:`9. Questions and Concerns`,
         ro:`9. Întrebări și Preocupări`
      },
    92: {
         en:`For questions or concerns, contact us at parentsadvisor.app@gmail.com`,
         ro:`Pentru întrebări sau nelămuriri, contactați-ne la parentsadvisor.app@gmail.com`
      },
    93: {
         en:`Appendix A – Definitions`,
         ro:`Anexa A – Definiții`
      },
    94: {
         en:`● "Affiliate": An entity affiliated with Parent's Advisor.`,
         ro:`● "Afiliat": Entitate afiliată cu Parent's Advisor.`
      },
    95: {
         en:`● "Application": The mobile application of Parent's Advisor.`,
         ro:`● "Aplicație": Aplicația mobilă Parent's Advisor.`
      },
    96: {
         en:`● "Child" or "Children": Defined according to applicable law.`,
         ro:`● "Copil" sau "Copii": Definiți conform legii aplicabile.`
      },
    97: {
         en:`● "Personal Information": Information that identifies or can be used to identify a person.`,
         ro:`● "Informații personale": Informații care identifică sau pot identifica o persoană.`
      },
    98: {
         en:`● "Services": The services offered by Parent's Advisor.`,
         ro:`● "Servicii": Serviciile oferite de Parent's Advisor.`
      },
    99: {
         en:`● "User": Any person using the services of Parent's Advisor.`,
         ro:`● "Utilizator": Orice persoană care utilizează serviciile Parent's Advisor.`
      },
    100: {
         en:``,
         ro:``
      },
    101: {
         en:``,
         ro:``
      },
    102: {
         en:``,
         ro:``
      },
    103: {
         en:``,
         ro:``
      },
    104: {
         en:``,
         ro:``
      },
    105: {
         en:``,
         ro:``
      },
    106: {
         en:``,
         ro:``
      },
    107: {
         en:``,
         ro:``
      },
    108: {
         en:``,
         ro:``
      },
    109: {
         en:``,
         ro:``
      },
    110: {
         en:``,
         ro:``
      },
};
export default generalTextTranslations;
import IconSVG from '../IconSVG';


function H24SvgIcon(props) {  
    // const color = props.c || 'black';
    
    const lineWidth=0;
    const svgWidth = 310;
    const svgHeight = 310;    
    const d1="M308.417,122.685c-2.317-4.607-7.223-7.408-12.292-6.967l-15.409,1.126\n" +
    "c-16.714-60.412-72.04-104.968-137.706-104.968C64.154,11.875,0,76.034,0,154.884c0,78.856,64.154,143.009,143.009,143.009\n" +
    "c45.645,0,88.934-22.083,115.798-59.063c4.123-5.689,2.855-13.63-2.823-17.764c-5.689-4.128-13.636-2.845-17.759,2.817\n" +
    "c-22.099,30.421-57.692,48.587-95.222,48.587c-64.839,0-117.582-52.748-117.582-117.582S78.165,37.308,143.004,37.308\n" +
    "c52.22,0,96.549,34.244,111.838,81.434l-8.023,0.587c-5.124,0.37-9.524,3.807-11.139,8.681\n" +
    "c-1.621,4.884-0.131,10.258,3.753,13.619l23.083,19.934c2.246,3.617,6.217,6.037,10.775,6.037c0.239,0,0.462-0.054,0.696-0.065\n" +
    "c0.076,0,0.136,0.033,0.207,0.033c0.305,0,0.615-0.005,0.93-0.033c3.361-0.25,6.483-1.822,8.692-4.373l22.849-26.456\n" +
    "C310.038,132.818,310.723,127.275,308.417,122.685z";

const d2="M75.772,199.191v-12.347l11.259-10.176c19.031-17.024,28.278-26.815,28.544-36.997\n" +
    "c0-7.109-4.286-12.733-14.348-12.733c-7.5,0-14.071,3.742-18.629,7.239l-5.765-14.62c6.57-4.944,16.752-8.974,28.55-8.974\n" +
    "c19.706,0,30.562,11.525,30.562,27.342c0,14.609-10.584,26.276-23.187,37.53l-8.044,6.701v0.261h32.841v16.763H75.772V199.191z";

const d3="M186.261,199.191v-20.783H147.66v-13.26l32.972-53.091h24.933v51.073h10.454v15.278h-10.454\n" +
    "v20.783C205.564,199.191,186.261,199.191,186.261,199.191z M186.261,163.13v-19.298c0-5.232,0.267-10.584,0.669-16.219h-0.533\n" +
    "c-2.823,5.635-5.102,10.726-8.044,16.219l-11.661,19.031v0.267H186.261z";



    return (
        <IconSVG 
        id={'24h'}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            lineWidth={lineWidth}
            d1={d1}
            d2={d2}
            d3={d3}
             
            className={props.className}
            style={{...props.style}}
        />
    );
};

export default H24SvgIcon;
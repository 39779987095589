import classes from './Blog.module.scss';
import articolPicture1 from "../../../FILES/Images/Articol1.webp";
import PIS from '../../../COMPONENTS/UI/Texts/PIS';
import PB from '../../../COMPONENTS/UI/Texts/PB';
import PI from '../../../COMPONENTS/UI/Texts/PI';
import P2I from '../../../COMPONENTS/UI/Texts/P2I';

import useGetTranslatedText from '../../../HOOKS/useGetTranslatedText';

function BlogActivitati(props) {
    const text = useGetTranslatedText('blog1_TextTranslations.js');
  return (      
        <div className={classes.mainContainer}>
            <div className={classes.topContainer}>
                <div className={classes.titleContainer}>
                    <h1>{text[1]}</h1>
                </div>
                <div className={classes.pictureContainer}>
                    <img className={classes.img} src={articolPicture1} alt={'parents advisor application'}/>    
                </div>  
            </div>

            <div className={classes.textContainer}>
                <PI t={text[2]}/>
                <PI t={text[3]}/>
                <PI t={text[5]}/> 
                <PI t={text[7]}/>
                <PI t={text[8]}/>
                    <P2I t={text[9]}/>
                    <P2I t={text[10]}/>
                    <P2I t={text[11]}/>
                    <P2I t={text[12]}/>
                <PI t={text[13]}/>
                <PIS t={text[14]}/>
                <PIS t={text[15]}/>
               <PB t={text[16]}/>
                <PIS t={text[17]}/>
               <PB t={text[18]}/>
                <PIS t={text[19]}/>
               <PB t={text[20]}/>
                <PIS t={text[21]}/>
                <PIS t={text[22]}/>
                
            </div>
                                                                                                       
         </div>
  );
}

export default BlogActivitati;

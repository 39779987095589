import classes from './Privacy.module.css';
import Background from '../COMPONENTS/UI/Background/Background';

import Footer from '../COMPONENTS/UI/Footer/Footer';

import PIS from '../COMPONENTS/UI/Texts/PIS';
import PI from '../COMPONENTS/UI/Texts/PI';
import P2I from '../COMPONENTS/UI/Texts/P2I';
import P2IS from '../COMPONENTS/UI/Texts/P2IS';
import PB from '../COMPONENTS/UI/Texts/PB';
import PBS from '../COMPONENTS/UI/Texts/PBS';
import H3 from '../COMPONENTS/UI/Texts/H3';
import S from '../COMPONENTS/UI/Texts/S';
import S2 from '../COMPONENTS/UI/Texts/S2';
import P from '../COMPONENTS/UI/Texts/P';

import { useEffect } from 'react';
import useGetTranslatedText from '../HOOKS/useGetTranslatedText';

function Privacy(props) {
    const text = useGetTranslatedText('privacyPage_TextTranslations.js');

    useEffect(()=>{
        props.onLoad('privacy');
    },[props])  
 
  return (
    <Background>        
        <div className={classes.container}>
                <H3 t={text[1]}/>
                <P t={text[2]}/>
                    {/* 1 */}
                    <S/>
                    <PB t={text[3]}/>
                        <PIS t={text[4]}/>

                    {/* 2 */}                        
                    <PB t={text[5]}/>
                        <PI t={text[6]}/>
                        <PI t={text[7]}/>
                        <PIS t={text[8]}/>

                    {/* 3 */}       
                    <PB t={text[9]}/>
                        <PI t={text[10]}/>
                        <PI t={text[11]}/>
                        <PI t={text[12]}/>
                        <PI t={text[13]}/>
                        <PI t={text[14]}/>
                        <PI t={text[15]}/>
                        <PIS t={text[16]}/>
                    
                    {/* 4 */}       
                    <PB t={text[17]}/>
                        <PI t={text[18]}/>
                        <PI t={text[19]}/>
                        <P2I t={text[20]}/>
                        <P2IS t={text[21]}/>

                        <PB t={text[22]}/>
                        <PI t={text[23]}/>
                        <PI t={text[24]}/>
                        <PI t={text[25]}/>
                            <P2I t={text[26]}/>
                            <P2I t={text[27]}/>
                            <P2I t={text[28]}/>
                            <P2I t={text[29]}/>
                            <P2I t={text[30]}/>
                        
                        <P t={text[31]}/>
                        <PIS t={text[32]}/>

                    {/* 5 */}
                    <PB t={text[35]}/>
                        <PI t={text[36]}/>
                        <PI t={text[37]}/>
                        <PI t={text[38]}/>
                        <PI t={text[39]}/>
                        <PI t={text[40]}/>
                        <PI t={text[41]}/>
                        <PI t={text[42]}/>
                        <PI t={text[43]}/>
                            <P2I t={text[44]}/>
                            <P2I t={text[45]}/>
                            <P2I t={text[46]}/>
                            <P2I t={text[47]}/>
                            <P2I t={text[48]}/>
                            <P2I t={text[49]}/>
                            <P2I t={text[50]}/>
                        <PI t={text[51]}/>
                        <PI t={text[52]}/>
                            <P2I t={text[53]}/>
                            <P2I t={text[54]}/>
                            <P2I t={text[55]}/>
                            <P2I t={text[56]}/>
                            <P2IS t={text[57]}/>   

                    {/* 6 */}
                    <PB t={text[60]}/>
                        <PI t={text[61]}/>
                        <PI t={text[62]}/>        
                            <P2I t={text[63]}/>
                            <P2I t={text[64]}/>
                            <P2I t={text[65]}/>
                            <P2I t={text[66]}/>  
                        <PI t={text[67]}/>        
                            <PI t={text[68]}/>    
                        <PI t={text[69]}/>        
                            <PI t={text[70]}/>  
                        <PI t={text[71]}/>        
                            <PI t={text[72]}/> 
                        <PI t={text[73]}/>        
                            <PI t={text[74]}/> 
                        <PI t={text[75]}/>        
                            <PI t={text[76]}/>        
                        <PI t={text[77]}/> 
                            <PI t={text[78]}/>        
                        <PI t={text[79]}/> 
                            <PI t={text[80]}/>         
                        <PI t={text[81]}/> 
                            <PIS t={text[82]}/>        

                    {/* 7 */}
                    <PB t={text[85]}/>
                        <PIS t={text[86]}/>

                
                    {/* 8 */}
                    <PB t={text[87]}/>
                        <PIS t={text[88]}/>

                        
                    {/* 9 */}
                    <PB t={text[91]}/>
                        <PIS t={text[92]}/>    

                    <P t={text[93]}/> 
                    <PI t={text[94]}/>
                    <PI t={text[95]}/>
                    <PI t={text[96]}/>
                    <PI t={text[97]}/>
                    <PI t={text[98]}/>
                    <PI t={text[99]}/>
                                                                                                               
         </div>
         <Footer/>
    </Background>
  );
}

export default Privacy;

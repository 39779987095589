import classes from './Blog.module.scss';
import articolPicture3 from "../../../FILES/Images/Articol3.webp";

import PIS from '../../../COMPONENTS/UI/Texts/PIS';
import PI from '../../../COMPONENTS/UI/Texts/PI';
import P2I from '../../../COMPONENTS/UI/Texts/P2I';

import useGetTranslatedText from '../../../HOOKS/useGetTranslatedText';

function BlogBeneficii(props) {
    const text = useGetTranslatedText('blog3_TextTranslations.js');
  return (      
        <div className={classes.mainContainer}>
            <div className={classes.topContainer}>
                <div className={classes.titleContainer}>
                    <h1>{text[1]}</h1>
                </div>
                <div className={classes.pictureContainer}>
                    <img className={classes.img} src={articolPicture3} alt={'parents advisor application'}/>    
                </div>  
            </div>

            <div className={classes.textContainer}>
                <PIS t={text[2]}/>
                <PI t={text[3]}/>
                    <P2I t={text[4]}/>
                    <P2I t={text[5]}/>
                    <P2I t={text[6]}/>
                    <P2I t={text[7]}/>
                    <P2I t={text[8]}/>
                    <P2I t={text[9]}/>
                    <P2I t={text[10]}/>
                    <P2I t={text[11]}/>                    
                    <P2I t={text[12]}/>
                <PI t={text[13]}/>
                    <P2I t={text[14]}/>
                <PIS/>
                <PI t={text[15]}/>
                <PIS t={text[16]}/>

                
            </div>
                                                                                                       
         </div>
  );
}

export default BlogBeneficii;


import Background from '../../COMPONENTS/UI/Background/Background';
import Footer from '../../COMPONENTS/UI/Footer/Footer';
import { useEffect } from 'react';
import BlogCumAlegi from './components/BlogCumAlegi';

function CumAlegi(props) {
    
    useEffect(()=>{
        props.onLoad('blog');
    },[props])  
 
  return (
    <Background>        
        <BlogCumAlegi/>
        <Footer/>
    </Background>
  );
}

export default CumAlegi;

import { createSlice } from '@reduxjs/toolkit';

const storeGeneral_Slice = createSlice({
    name: 'storeGeneralSlice',
    initialState: {
         selectedLanguage: 'ro',
        
    },
    reducers: {
        changeLang(state, action) {
            state.selectedLanguage = action.payload.lang; 
        },         
        
    },
});

export const storeGeneral_Actions = storeGeneral_Slice.actions;

export default storeGeneral_Slice.reducer;

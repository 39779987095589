// import classes from './H3.module.css';
import { Fragment } from 'react';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';

function H3 (props){
    const pageText = useGetTranslatedText('termsPage_TextTranslations.js');

    return (
        <Fragment>
            <h3><b>{pageText[props.t]}</b></h3>
            <p>&nbsp;</p>
        </Fragment>
    );
};

export default H3;
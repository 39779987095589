const blog3TextTranslations= {
    0: {
         en:``,
         ro:``
      },
    1: {
         en:``,
         ro:`TOP 10 beneficii ale dansului pentru dezvoltarea copiilor`
      },
    2: {
         en:``,
         ro:`Mai ții minte când copilul tău a început să danseze? Cel mai probabil, atunci când nu știa nici măcar să meargă. Un prunc care reacționează la muzică, făcând anumite mișcări, învață, de fapt, primele forme de exprimare. Copiii dansează oriunde: în mijlocul străzii, în parc, în restaurant și în mașină, ceea ce îi face pe părinții lor să se gândească să-i înscrie la cursuri de dans. Dacă ești un părinte de băiat, nu te grăbi să renunți la această idee, crezând că este o activitate pentru fete.`
      },
    3: {
         en:``,
         ro:`Orice copil care participă la ore de dans va avea multe beneficii, printre care:`
      },
    4: {
         en:``,
         ro:`1. Dezvoltarea echilibrului`
      },
    5: {
         en:``,
         ro:`2. Consolidarea memoriei`
      },
    6: {
         en:``,
         ro:`3. Relaxarea`
      },
    7: {
         en:``,
         ro:`4. Creşterea creativităţii`
      },
    8: {
         en:``,
         ro:`5. Ajutor la depășirea stărilor de anxietate, depresie și insomnie
`
      },
    9: {
         en:``,
         ro:`6. Creșterea coordonării`
      },
    10: {
         en:``,
         ro:`7. Îmbunătățirea posturii`
      },
    11: {
         en:``,
         ro:`8. Combaterea excesului de greutate`
      },
    12: {
         en:``,
         ro:`9. Creşterea stimei de sine`
      },
    13: {
         en:``,
         ro:`…și, probabil, cel mai surprinzător beneficiu`
      },
    14: {
         en:``,
         ro:`10. Ajutor în matematică`
      },
    15: {
         en:``,
         ro:`Da, și știi de ce? Deoarece dansul presupune învățarea coregrafiei, ceea ce sporește capacitatea logică și ajută la o concentrare mai bună!`
      },
    16: {
         en:``,
         ro:`Pe lângă toate aceste beneficii, dansul poate să-i deschidă copilului tău orizonturi profesionale, deoarece este o abilitate care se poate transforma într-o carieră de dansator, coregraf sau regizor.`
      },
    17: {
         en:``,
         ro:``
      },
    
};
export default blog3TextTranslations;
import classes from './PInos.module.scss';
import { Fragment } from 'react';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';

function PInos (props){
    const pageText = useGetTranslatedText('termsPage_TextTranslations.js');

    return (
        <Fragment>
            <p className={classes.indent}>{pageText[props.t]}</p>
        </Fragment>
    );
};

export default PInos;
const termsTextTranslations= {
    0: {
         en:``,
         ro:``
      },
    1: {
         en:`Effective Date: December 1, 2023`,
         ro:`Data efectivă: 01.12.2023`
      },
    2: {
         en:`Welcome to Parent's Advisor!`,
         ro:`Bine ați venit la Parent's Advisor!`
      },
    3: {
         en:`Introduction:`,
         ro:`Introducere:`
      },
    4: {
         en:`Parent's Advisor is an innovative virtual psychologist assistant, designed to support parents and children in their personal and professional development journey. Parent's Advisor offers an interactive environment for vocational exploration, utilizing advanced technologies to help identify children's interests and abilities. By using Parent's Advisor, you will have access to personalized activity suggestions and educational resources tailored to promote your child's happiness and harmonious development.`,
         ro:`Parent's Advisor este un asistent psiholog virtual inovator, creat pentru a sprijini părinții și copiii în călătoria lor de dezvoltare personală și profesională.  Parent's Advisor oferă un mediu interactiv de explorare vocațională, folosind tehnologii avansate pentru a ajuta la identificarea intereselor și abilităților copiilor. Prin utilizarea Parent's Advisor, veți avea acces la sugestii personalizate de activități și resurse educaționale adaptate pentru a promova fericirea și dezvoltarea armonioasă a copilului dumneavoastră.`
      },
    5: {
         en:`What Follows:`,
         ro:`Ce urmează:`
      },
    6: {
         en:`Before you start using Parent's Advisor, please carefully read our terms and conditions. They will guide you in the responsible and efficient use of our platform and inform you about your rights and responsibilities as a user.`,
         ro:`Înainte de a începe să utilizați Parent's Advisor, vă rugăm să citiți cu atenție termenii și condițiile noastre. Acestea vă vor ghida în utilizarea responsabilă și eficientă a platformei noastre și vă vor informa despre drepturile și responsabilitățile dvs. ca utilizator.`
      },
    7: {
         en:`1. General Aspects:`,
         ro:`1. Aspecte generale:`
      },
    8: {
         en:`1.1. Acceptance of Terms:`,
         ro:`1.1. Acceptarea Termenilor:`
      },
    9: {
         en:`By using the services and platform of Parent's Advisor, you accept these terms, including our policy on privacy and responsible use. These terms create a legal agreement between you and Parent's Advisor.`,
         ro:`Prin utilizarea serviciilor și platformei Parent's Advisor, acceptați acești termeni, inclusiv politica noastră privind confidențialitatea și utilizarea responsabilă. Acești termeni creează un acord legal între dvs. și Parent's Advisor.`
      },
    10: {
         en:`1.2. Conditions of Use:`,
         ro:`1.2. Condiții de Utilizare:`
      },
    11: {
         en:`Parent's Advisor services are available under certain conditions. If you do not agree with these conditions, you should not use the Parent's Advisor services.`,
         ro:`Serviciile Parent's Advisor sunt disponibile sub anumite condiții. Dacă nu sunteți de acord cu aceste condiții, nu trebuie să folosiți serviciile Parent's Advisor.`
      },
    12: {
         en:`1.3. Privacy Policy:`,
         ro:`1.3. Politica de Confidențialitate:`
      },
    13: {
         en:`We collect only your child's name and age, which you personally as a parent enter. We try to use as few data as possible, only those strictly necessary for account collection: email and name. These data are treated in accordance with our Privacy Policy. Please read it to understand how these data are managed and protected.`,
         ro:`Nu colectăm informații despre copilul dvs. decât numele și vârsta pe care dvs personal ca parinte o introduceți. Încercăm să folosim cât mai puține date, doar cele strcit necesare pentru colectarea contului: email și nume. Aceste date sunt tratate conform Politicii noastre de Confidențialitate. Vă rugăm să o citiți pentru a înțelege cum sunt gestionate și protejate aceste date.`
      },
    14: {
         en:`1.4. Responsible Use:`,
         ro:`1.4. Utilizare Responsabilă:`
      },
    15: {
         en:`Parent's Advisor services should only be used for permitted purposes, respecting the rights and integrity of each user.`,
         ro:`Serviciile Parent's Advisor trebuie folosite numai pentru scopurile permise, respectând drepturile și integritatea fiecărui utilizator.`
      },
    16: {
         en:`1.5. User Responsibility:`,
         ro:`1.5. Responsabilitatea Utilizatorului:`
      },
    17: {
         en:`Parent's Advisor is not responsible for the accuracy of information provided by users or for verifying their identity.`,
         ro:`Parent's Advisor nu este responsabil pentru acuratețea informațiilor furnizate de utilizatori sau pentru verificarea identității acestora.`
      },
    18: {
         en:`1.6. Legal Actions:`,
         ro:`1.6. Acțiuni Legale:`
      },
    19: {
         en:`Parent's Advisor reserves the right to take legal action against those who misrepresent information or falsify their identity.`,
         ro:`Parent's Advisor își rezervă dreptul de a întreprinde acțiuni legale împotriva celor care denaturează informații sau își falsifică identitatea.`
      },
    20: {
         en:`1.7. Intellectual Property:`,
         ro:`1.7. Intellectual Property:`
      },
    21: {
         en:`All intellectual property rights over the application and its content are owned and licensed by Parent's Advisor. You agree not to copy, modify, distribute, sell, or exploit any part of the application without our prior written consent.`,
         ro:`Toate drepturile de proprietate intelectuală asupra aplicației și conținutului său sunt deținute și licențiate de către Parent's Advisor. Sunteți de acord să nu copiați, modificați, distribuiți, vindeți sau exploatați nicio parte a aplicației fără acordul nostru scris în prealabil.`
      },
    22: {
         en:`2. User Content:`,
         ro:`2. Conținutul Utilizatorului:`
      },
    23: {
         en:`2.1. User Actions:`,
         ro:`2.1. Acțiunile utilizatorului:`
      },
    24: {
         en:`By using the Parent's Advisor application, you can send or upload various types of content, such as feedback on activities, suggestions, images, or other educational materials ("User Content"). You retain all property rights over the User Content.

`,
         ro:`Prin utilizarea aplicației Parent's Advisor, puteți trimite sau încărca diferite tipuri de conținut, cum ar fi feedback-ul legat de activități, sugestii, imagini sau alte materiale educaționale ("Conținutul Utilizatorului"). Dețineți toate drepturile de proprietate asupra Conținutului Utilizatorului.`
      },
    25: {
         en:`2.2. Confidentiality and Use of Content:`,
         ro:`2.2. Confidențialitatea și Utilizarea Conținutului:`
      },
    26: {
         en:`
The content you upload to Parent's Advisor is private and accessible only to you. If you decide to share certain information with others, you do so at your own risk. Parent's Advisor may use this content to provide and improve our services.
`,
         ro:`
Conținutul pe care îl încărcați în Parent's Advisor este privat și accesibil doar pentru dumneavoastră. Dacă decideți să împărtășiți anumite informații cu alte persoane, o faceți pe propria răspundere. Parent's Advisor poate utiliza acest conținut în scopul furnizării și îmbunătățirii serviciilor noastre.
`
      },
    27: {
         en:`2.3. License Granted to Parent's Advisor:`,
         ro:`2.3. Licența Acordată Parent's Advisor:`
      },
    28: {
         en:`
By using Parent's Advisor, you grant the application a limited, global, and non-exclusive license to access, use, and process your content for reasonable purposes, such as providing services and addressing technical issues. Parent's Advisor will delete your content upon request or upon closing your account, except for content that becomes public, managed according to our privacy policy.
`,
         ro:`
Prin utilizarea Parent's Advisor, acordați aplicației o licență limitată, mondială și neexclusivă pentru a accesa, utiliza și procesa conținutul dumneavoastră în scopuri rezonabile, cum ar fi furnizarea serviciilor și abordarea problemelor tehnice. Parent's Advisor va elimina conținutul dumneavoastră la cerere sau la închiderea contului, cu excepția conținutului care devine public, gestionat conform politicii noastre de confidențialitate.
`
      },
    29: {
         en:`2.4. Your Responsibility for Content:`,
         ro:`2.4. Responsabilitatea dumneavoastră pentru Conținut:`
      },
    30: {
         en:`
You are responsible for the content uploaded to Parent's Advisor and must have all the necessary rights for it. Parent's Advisor does not verify the accuracy or opinions expressed in the User Content.
`,
         ro:`
Sunteți responsabil pentru conținutul încărcat pe Parent's Advisor și trebuie să dețineți toate drepturile necesare pentru acesta. Parent's Advisor nu verifică acuratețea sau opiniile exprimate în Conținutul Utilizatorului.
`
      },
    31: {
         en:`2.5. Monitoring User Content:`,
         ro:`2.5. Monitorizarea Conținutului Utilizatorului:`
      },
    32: {
         en:`
Parent's Advisor may review, monitor, and, if necessary, remove any content that violates our terms and conditions.
`,
         ro:`
Parent's Advisor poate revizui, monitoriza și, după caz, elimina orice conținut care încalcă termenii și condițiile noastre.
`
      },
    33: {
         en:`2.6. Public User Content:`,
         ro:`2.6. Conținut Public al Utilizatorului:`
      },
    34: {
         en:`
If you choose to make your content public, you grant Parent's Advisor a broad license to use and distribute this content in various forms and media.
`,
         ro:`
Dacă alegeți să faceți public conținutul dumneavoastră, acordați Parent's Advisor o licență largă pentru a utiliza și distribui acest conținut în diverse forme și medii.
`
      },
    35: {
         en:`2.7. Verified Creators on the Parent's Advisor Platform:`,
         ro:`2.7. Creatori Verificați pe Platforma Parent's Advisor:`
      },
    36: {
         en:`
The Parent's Advisor platform allows verified creators to offer educational content and resources to our users. We will ask for your explicit consent each time you wish to receive this content.
`,
         ro:`
Platforma Parent's Advisor permite creatorilor verificați să ofere conținut educațional și resurse către utilizatorii noștri. Vă vom cere consimțământul explicit de fiecare dată când doriți să primiți acest conținut.
`
      },
    37: {
         en:`3.Service Plans Available at Parent's Advisor`,
         ro:`3. Planuri de Servicii Disponibile la Parent's Advisor`
      },
    38: {
         en:`Parent's Advisor offers a variety of service plans to meet the diverse needs of our users. Detailed information about these plans can be found in the application under the subscriptions section in settings or on our website. Our plans are classified as follows:`,
         ro:`Parent's Advisor oferă o varietate de planuri de servicii pentru a răspunde nevoilor diverse ale utilizatorilor noștri. Informații detaliate despre aceste planuri pot fi găsite în aplicație în secțiunea abonamente din setări sau pe site-ul nostru web. Planurile noastre sunt clasificate astfel:`
      },
    39: {
         en:`3.1. Free Service Plans:`,
         ro:`3.1. Planuri de Servicii Gratuite:`
      },
    40: {
         en:`These plans provide access to the basic functions of the Parent's Advisor platform and are ideal for non-commercial use. They are suitable for parents and children who wish to explore the basic functionalities of the application.`,
         ro:`Aceste planuri oferă acces la funcții de bază ale platformei Parent's Advisor și sunt ideale pentru utilizarea non-comercială. Sunt potrivite pentru părinți și copii care doresc să exploreze funcționalitățile de bază ale aplicației.`
      },
    41: {
         en:`3.2. Paid Service Plans:`,
         ro:`3.2. Planuri de Servicii Plătite:`
      },
    42: {
         en:`Paid plans offer access to advanced features and additional resources, suitable for advanced use in educational and personal development contexts. The features and limitations of each plan vary, and users choose the service plan (Pro or Premium subscription) based on personal preferences and needs.`,
         ro:`Planurile plătite oferă acces la funcții avansate și resurse suplimentare, fiind adecvate pentru utilizare avansată în contexte educaționale și de dezvoltare personală. Caracteristicile și limitările fiecărui plan variază, iar utilizatorii aleg planul de servicii (abonamentul Pro sau Premium) în funcție de preferințele și necesitățile personale.`
      },
    43: {
         en:`3.3. Managing Plans and Subscriptions:`,
         ro:`3.3. Gestionarea Planurilor și Abonamentelor:`
      },
    44: {
         en:`Service plans and content subscriptions can be managed directly in the app or through external platforms (e.g., Google Play, Apple App Store) for purchases made through them.`,
         ro:`Planurile de servicii și abonamentele de conținut pot fi gestionate direct în aplicație sau prin platforme externe (ex. Google Play, Apple App Store) pentru achiziții efectuate prin acestea.`
      },
    45: {
         en:`3.4. Payments, Cancellations, and Upgrades:`,
         ro:`3.4. Plăți, Anulări, și Upgrade-uri:`
      },
    46: {
         en:`Paid plans and subscriptions require advance payment and are generally non-refundable, except as provided by applicable law. Subscriptions automatically renew at the end of each period, according to the recorded payment details. Cancellations can be made before the end of the current period, with effect at the end of that period.`,
         ro:`Planurile plătite și abonamentele necesită plată în avans și sunt, în general, nerambursabile, cu excepții conform legislației aplicabile. Abonamentele se reînnoiesc automat la sfârșitul fiecărei perioade, conform detaliilor de plată înregistrate. Anulările pot fi efectuate înainte de sfârșitul perioadei curente, cu efect la finalul acesteia.`
      },
    47: {
         en:`3.5. Flexibility of Plans:`,
         ro:`3.5. Flexibilitatea Planurilor:`
      },
    48: {
         en:`Parent's Advisor allows you to upgrade or downgrade to any paid plan at any time during the subscription. Upon upgrade, the change takes effect immediately after the upgrade fee is paid. Upon downgrade, the change occurs at the end of the current subscription period.`,
         ro:`Parent's Advisor permite upgrade sau downgrade la orice plan plătit, în orice moment al abonamentului. La upgrade, schimbarea intră în vigoare imediat după plata taxei de upgrade. La downgrade, modificarea are loc la sfârșitul perioadei curente de abonament.`
      },
    49: {
         en:`3.6. Price Changes and New Plans:`,
         ro:`3.6. Modificări de Preț și Noi Planuri:`
      },
    50: {
         en:`Parent's Advisor reserves the right to change prices for plans and subscriptions. Any change will be effective at the beginning of the next subscription period, and users will be appropriately informed. In case of a price change, users have the option to cancel the service.`,
         ro:`Parent's Advisor își rezervă dreptul de a modifica prețurile pentru planuri și abonamente. Orice schimbare va fi efectivă la începutul următoarei perioade de abonament, iar utilizatorii vor fi informați corespunzător. În cazul unei modificări de preț, utilizatorii au opțiunea de a anula serviciul.`
      },
    51: {
         en:`3.7. Taxes:`,
         ro:`3.7. Impozite:`
      },
    52: {
         en:`Displayed prices may exclude applicable taxes, which will be calculated and added at the end of the transaction.`,
         ro:`Prețurile afișate pot exclude taxele aplicabile, care vor fi calculate și adăugate la finalizarea tranzacției.`
      },
    53: {
         en:`4. Account Security at Parent's Advisor`,
         ro:`4. Securitatea Contului la Parent's Advisor`
      },
    54: {
         en:`4.1. Your Responsibility for Account Security:`,
         ro:`4.1. Responsabilitatea Dvs. pentru Securitatea Contului:`
      },
    55: {
         en:`As a user of Parent's Advisor, you have the full responsibility for the security of your account and the devices used to access our services. It is crucial to maintain the security of these devices and not to disclose passwords or payment details. You are also responsible for updating and maintaining the accuracy of the information related to your account. In case of unauthorized or fraudulent use, Parent's Advisor reserves the right to suspend or close the account for the protection of all parties involved.`,
         ro:`Ca utilizator al Parent's Advisor, aveți responsabilitatea totală pentru securitatea contului și a dispozitivelor folosite pentru accesarea serviciilor noastre. Este crucial să mențineți securitatea acestor dispozitive și să nu dezvăluiți parolele sau detaliile de plată. Sunteți, de asemenea, responsabil pentru actualizarea și menținerea acurateței informațiilor legate de cont. În cazul unei utilizări neautorizate sau frauduloase, Parent's Advisor își rezervă dreptul de a suspenda sau închide contul pentru protecția tuturor părților implicate.`
      },
    56: {
         en:`4.2. Data Protection:`,
         ro:`4.2. Protejarea Datelor:`
      },
    57: {
         en:`Parent's Advisor is committed to protecting personal data in accordance with applicable laws and our privacy policies. We will not request or collect personal information beyond a short name and email address, nor will we ask for additional information.`,
         ro:`Parent's Advisor se angajează să protejeze datele personale în conformitate cu legislația aplicabilă și politicile noastre de confidențialitate. Nu vom cere sau colecta informații personale în afara unui nume scurt și adresei de email, nici nu vom solicita alte informații suplimentare.`
      },
    58: {
         en:`4.3. Term and Termination:`,
         ro:`4.3. Termenul și Rezilierea:`
      },
    59: {
         en:`The agreement between you and Parent's Advisor remains valid as long as you use our services. We may terminate this agreement or suspend access to our services in case of unauthorized use or breach of the terms of the agreement. In case of termination, we have no liability to you and will not refund any amounts already paid.`,
         ro:`Acordul între dvs. și Parent's Advisor rămâne valabil atâta timp cât utilizați serviciile noastre. Putem rezilia acest acord sau suspenda accesul la serviciile noastre în caz de utilizare neautorizată sau încălcarea termenilor acordului. În caz de reziliere, nu avem răspundere față de dvs. și nu vom rambursa sumele deja plătite.`
      },
    60: {
         en:`4.4. Warranty and Disclaimer:`,
         ro:`4.4. Garanție și Declinarea Răspunderii:`
      },
    61: {
         en:`Parent's Advisor will make reasonable efforts to ensure that the services function according to the descriptions on our website. However, we do not guarantee continuous or uninterrupted availability of the services. We are not responsible for problems caused by hardware, software, or other external components.`,
         ro:`Parent's Advisor va depune eforturi rezonabile pentru ca serviciile să funcționeze conform descrierilor de pe site-ul nostru. Totuși, nu garantăm disponibilitatea continuă sau fără întreruperi a serviciilor. Nu suntem responsabili pentru probleme cauzate de hardware, software sau alte componente externe.`
      },
    62: {
         en:`5. Limitation of Liability of Parent's Advisor`,
         ro:`5. Limitarea Răspunderii Parent's Advisor`
      },
    63: {
         en:`5.1. Scope of Liability:`,
         ro:`5.1. Extinderea Responsabilității:`
      },
    64: {
         en:`In accordance with applicable law, Parent's Advisor and its affiliates will not be liable for special, indirect, incidental, punitive, exemplary, or consequential damages, including the loss of data or profits. Our total liability is limited to the amount you have paid us in the last 12 months.`,
         ro:`Conform legislației aplicabile, Parent's Advisor și afiliații săi nu vor fi responsabili pentru daune speciale, indirecte, incidentale, punitive, exemplare sau consecutive, incluzând pierderea de date sau profituri. Responsabilitatea noastră totală este limitată la suma plătită de dvs. în ultimele 12 luni.`
      },
    65: {
         en:`5.2. Exceptions to Limitation of Liability:`,
         ro:`5.2. Excepții la Limitarea Răspunderii:`
      },
    66: {
         en:`This limitation does not exclude Parent's Advisor's liability for fraud, fraudulent misrepresentation, death or bodily injury caused by negligence, or other serious cases provided by law.`,
         ro:`Această limitare nu exclude răspunderea Parent's Advisor pentru fraudă, declarații frauduloase, deces sau vătămare corporală datorată neglijenței sau alte cazuri grave prevăzute de lege.`
      },
    67: {
         en:`5.3. Specific Terms for iOS Users:`,
         ro:`5.3. Termeni Specifici pentru Utilizatori iOS:`
      },
    68: {
         en:`When using the Parent's Advisor application on iOS devices, it is subject to Apple's terms and conditions, including limited rights to use the application on Apple devices.`,
         ro:`În cazul utilizării aplicației Parent's Advisor pe dispozitive iOS, aceasta este supusă termenilor și condițiilor Apple, inclusiv drepturile limitate de utilizare a aplicației pe dispozitivele Apple.`
      },
    69: {
         en:`5.4. Specific Apple Terms for iOS Users of Parent's Advisor Application`,
         ro:`5.4. Termeni Specifici Apple pentru Utilizatori iOS ai Aplicației Parent's Advisor`
      },
    70: {
         en:`Apple Inc. is not a party to these Terms and does not own or is not responsible for the Parent's Advisor application. Apple does not provide warranties for the application, except for the possibility of refunding the purchase price, if applicable. Apple is not responsible for maintenance or other support services for the Parent's Advisor application and will not be liable for any claims, losses, liabilities, damages, costs, or expenses related to the application. Any questions or complaints related to the use of the application, including those related to intellectual property rights, should be directed to Parent's Advisor. The license granted to you is limited to using the application on an Apple device running iOS.`,
         ro:`Apple Inc. nu este parte la acești Termeni și nu deține, nu este responsabilă pentru aplicația Parent's Advisor. Apple nu oferă garanții pentru aplicația, cu excepția posibilității de rambursare a prețului de achiziție, dacă este cazul. Apple nu este responsabilă pentru întreținerea sau asistența aplicației și nu va fi răspunzătoare pentru orice reclamații, pierderi, daune, costuri sau cheltuieli legate de aplicație. Orice întrebări sau plângeri referitoare la utilizarea aplicației, inclusiv cele privind drepturile de proprietate intelectuală, trebuie îndreptate către Parent's Advisor. Licența acordată dvs. este limitată la utilizarea aplicației pe un dispozitiv Apple care rulează iOS.`
      },
    71: {
         en:`6. Miscellaneous:`,
         ro:`6. Diverse:`
      },
    72: {
         en:`This agreement represents all terms agreed between you and Parent's Advisor and supersedes any prior agreements related to this subject.`,
         ro:`Acest acord reprezintă toți termenii conveniți între dvs. și Parent's Advisor și înlocuiește orice acorduri anterioare legate de subiectul acestuia.`
      },
    73: {
         en:`6.1. Communication Opportunities:`,
         ro:`6.1. Posibilități de comunicare:`
      },
    74: {
         en:`Our technical team works tirelessly to ensure the highest accuracy, completeness, and reliability for all users, and we encourage reporting any issues encountered while using the Parent's Advisor application or web platform at the following address:`,
         ro:`Echipa noastră tehnică lucrează neîncetat pentru a asigura cea mai bună precizie, completitudine și fiabilitate pentru toți utilizatorii, și încurajăm raportarea oricăror probleme întâmpinate în timpul utilizării aplicației sau platformei web Parent's Advisor la adresa:`
      },
    75: {
         en:`PARENT’S ADVISOR S.R.L.`,
         ro:`PARENT’S ADVISOR S.R.L.`
      },
    76: {
         en:`Timişoara Municipality, Strada ARMONIEI,`,
         ro:`Timişoara Municipality, Strada ARMONIEI,`
      },
    77: {
         en:`No. 23A, Apartment B1,`,
         ro:`No. 23A, Apartment B1,`
      },
    78: {
         en:`Timiș County`,
         ro:`Timiș County`
      },
    79: {
         en:`parentsadvisor.app@gmail.com`,
         ro:`parentsadvisor.app@gmail.com`
      },
    80: {
         en:`6.2. Changes to Terms:`,
         ro:`6.2. Modificări ale Termenilor:`
      },
    81: {
         en:`Parent's Advisor reserves the right to modify the terms of this agreement. Any changes will be appropriately communicated.`,
         ro:`Parent's Advisor își rezervă dreptul de a modifica termenii acestui acord. Orice modificare va fi comunicată în mod corespunzător.`
      },
    82: {
         en:`6.3. Applicable Law and Jurisdiction:`,
         ro:`6.3. Legea Aplicabilă și Jurisdicția:`
      },
    83: {
         en:`This agreement is governed by the laws of Romania. Any dispute will be resolved in the courts of Romania.`,
         ro:`Acest acord este guvernat de legile României. Orice litigiu va fi soluționat în instanțele din România.`
      },
    84: {
         en:`6.4 Definitions of Parent's Advisor`,
         ro:`6.4 Definiții Parent's Advisor`
      },
    85: {
         en:`"Acceptable Use Policy" means Parent's Advisor's acceptable use policy, periodically updated and available on our website.`,
         ro:`„Politica de Utilizare Acceptabilă” înseamnă politica Parent's Advisor de utilizare acceptabilă, actualizată periodic și disponibilă pe site-ul nostru web.`
      },
    86: {
         en:`"Administrator" means the person designated by your organization to manage the Parent's Advisor accounts of that organization, if applicable.`,
         ro:`„Administrator” înseamnă persoana desemnată de organizația dvs. pentru a gestiona conturile Parent's Advisor ale acelei organizații, dacă este cazul.`
      },
    87: {
         en:`"Agreement" refers to these Terms, the Acceptable Use Policy, any chosen Service Plan, and, if applicable, any Enterprise Agreement.`,
         ro:`„Acord” reprezintă acești Termeni, Politica de Utilizare Acceptabilă, orice Plan de Servicii ales și, dacă este aplicabil, orice Acord de Întreprindere.`
      },
    88: {
         en:`"Application" means the mobile applications and software of Parent's Advisor.`,
         ro:`„Aplicație” înseamnă aplicațiile mobile și software-ul Parent's Advisor.`
      },
    89: {
         en:`"Apple" means Apple Inc., in the context of using Parent's Advisor applications on iOS devices.`,
         ro:`„Apple” înseamnă Apple Inc., în contextul utilizării aplicațiilor Parent's Advisor pe dispozitive iOS.`
      },
    90: {
         en:`"Children" means minors under the age of 13 in the USA or under the age of 16 in other jurisdictions.`,
         ro:`„Copii” înseamnă minorii sub vârsta de 13 ani din SUA sau sub vârsta de 16 ani în alte jurisdicții.`
      },
    91: {
         en:`"Code" refers to codes or other promotional offers provided by Parent's Advisor for access to paid service plans or content subscriptions.`,
         ro:`„Cod” se referă la coduri sau alte oferte promoționale furnizate de Parent's Advisor pentru acces la planuri de servicii plătite sau abonamente de conținut.`
      },
    92: {
         en:`"Consumer" means an individual who uses Parent's Advisor services for non-commercial purposes.`,
         ro:`„Consumator” înseamnă o persoană fizică care utilizează serviciile Parent's Advisor fără scopuri comerciale.`
      },
    93: {
         en:`"Content Subscriptions" represent subscriptions to premium educational content libraries offered by Parent's Advisor, in addition to the chosen Service Plan.`,
         ro:`„Abonamente la Conținut” reprezintă abonamentele la biblioteci de conținut educațional premium oferite de Parent's Advisor, în plus față de Planul de Servicii ales.`
      },
    94: {
         en:`"Enterprise Agreement" refers to a separate contract between the Parent's Advisor and your associated organization.`,
         ro:`„Acord de Întreprindere” se referă la un contract separat între Parent's Advisor și organizația dvs. asociată.`
      },
    95: {
         en:`"Parent's Advisor" is the name of the company that provides Parent's Advisor services.`,
         ro:`„Parent's Advisor” este denumirea companiei care furnizează serviciile Parent's Advisor.`
      },
    96: {
         en:`"Parent's Advisor Brands" refers to all trademarks, service marks, trade names, logos, and other distinctive brand features of Parent's Advisor.`,
         ro:`„Parent's Advisor Brands” se referă la toate mărcile comerciale, mărcile de servicii, nume comerciale, logo-uri și orice alte caracteristici distinctive ale mărcii Parent's Advisor.`
      },
    97: {
         en:`"License" means the right to use granted under Section 3 of these Terms.`,
         ro:`„Licență” înseamnă dreptul de utilizare acordat conform secțiunii 3 a acestor Termeni.`
      },
    98: {
         en:`"Platform" means the websites and applications of Parent's Advisor where services are available.`,
         ro:`„Platformă” înseamnă site-urile web și aplicațiile Parent's Advisor unde serviciile sunt disponibile.`
      },
    99: {
         en:`"Public User Content" refers to content created by users and made available to the general public through Parent's Advisor services.`,
         ro:`„Conținut Public de Utilizator” se referă la conținutul creat de utilizatori și făcut disponibil publicului larg prin serviciile Parent's Advisor.`
      },
    100: {
         en:`"Resources" means the information, services, products, and tools offered through Parent's Advisor.`,
         ro:`„Resurse” înseamnă informațiile, serviciile, produsele și instrumentele oferite prin Parent's Advisor.`
      },
    101: {
         en:`"Service Plan" represents the various subscription plans for Parent's Advisor services.`,
         ro:`„Plan de Servicii” reprezintă diferitele planuri de abonament pentru serviciile Parent's Advisor.`
      },
    102: {
         en:`"Services" means the educational and other services provided through Parent's Advisor.`,
         ro:`„Servicii” înseamnă serviciile educaționale și alte servicii oferite prin Parent's Advisor.`
      },
    103: {
         en:`"Subscription Period" means the duration for which a Service Plan or a Content Subscription is active.`,
         ro:`„Perioada de Abonament” înseamnă durata pentru care un Plan de Servicii sau un Abonament de Conținut este activ.`
      },
    104: {
         en:`"Terms" refers to the general terms and conditions of using Parent's Advisor services.`,
         ro:`„Termeni” se referă la termenii și condițiile generale ale utilizării serviciilor Parent's Advisor.`
      },
    105: {
         en:``,
         ro:``
      },
    106: {
         en:``,
         ro:``
      },
    107: {
         en:``,
         ro:``
      },
    108: {
         en:``,
         ro:``
      },
    109: {
         en:``,
         ro:``
      },
    110: {
         en:``,
         ro:``
      },
    111: {
         en:``,
         ro:``
      },
    112: {
         en:``,
         ro:``
      },
    113: {
         en:``,
         ro:``
      },
    114: {
         en:``,
         ro:``
      },
    115: {
         en:``,
         ro:``
      },
    116: {
         en:``,
         ro:``
      },
};
export default termsTextTranslations;
const blog2TextTranslations= {

    0: {
         en:``,
         ro:``
      },
    1: {
         en:``,
         ro:`Cum alegi activitățile extrașcolare pentru copilul tău?`
      },
    2: {
         en:``,
         ro:`Un studiu recent afirmă că 80% din copii își petrec timpul liber în fața calculatorului sau televizorului! Atunci când au cea mai multă energie, dar și timp, pentru a descoperi, copiii duc un stil de viață sedentar, devenind tot mai introvertiți.`
      },
    3: {
         en:``,
         ro:`Cum îi putem ajuta?`
      },
    4: {
         en:``,
         ro:`O metodă recomandată de psihologi și testată de sute de mii de părinți este înscrierea copilului la activități extraşcolare, care îl vor ține departe de ecrane.`
      },
    5: {
         en:``,
         ro:`De ce să ții cont în acest sens?`
      },
    6: {
         en:``,
         ro:`Asigură-te că respecți preferințele copilului`
      },
    7: {
         en:``,
         ro:`Întreabă-l ce i-ar plăcea să facă și nu insista să meargă la activitățile care ți se par mai atractive. Un copil are puţine şanse de a face performanţă în balet doar pentru că mama lui a visat să fie balerină. Micii exploratori au nevoie să simtă că iau decizii și că pot alege ce e mai bine pentru ei. Și, de multe ori au și dreptate.`
      },
    8: {
         en:``,
         ro:`Creează-i un program diversificat și evită extremele`
      },
    9: {
         en:``,
         ro:`Dacă pui accent doar pe dezvoltarea fizică, rişti să neglijezi laturile intelectuale şi artistice. Iar un copil încărcat cu activități intelectuale, are nevoie și de o alternativă mai activă din punct de vedere fizic. Oferă-i celui mic o varietate de activități extracurriculare care să nu-l obosească, dar care să-i satisfacă, în acelaşi timp, nevoia de instruire şi de achiziţie de noi abilităţi.

`
      },
    10: {
         en:``,
         ro:`Optează pentru locații din proximitate`
      },
    11: {
         en:``,
         ro:`Astfel nu vei pierde timpul și energia în trafic, iar copilul va fi mai puțin tentat să te convingă să nu-l duci la activitate. Mai mult decât atât, plimbările săptămânale până la locație pot deveni ocazii de a avea cele mai sincere discuții!`
      },
    12: {
         en:``,
         ro:`Chiar dacă activitățile extrașcolare au foarte multe beneficii în dezvoltare, câteodată pot deveni obositoare pentru copii. Dacă observi că îi dispare cheful de a mai participa la activități, când nu mai povestește cu pasiune despre ce face acolo, permite-i să facă o pauză. Niciodată nu e greșit să renunțe la o activitate, așa cum niciodată nu e târziu să-și descopere noile pasiuni.`
      },
    13: {
         en:``,
         ro:``
      },
   
};
export default blog2TextTranslations;
import classes from './Reasons.module.scss';

// import icon1 from "../../../../FILES/Images/icon1.png";
// import icon2 from "../../../../FILES/Images/icon2.png";
// import icon3 from "../../../../FILES/Images/icon3.png";
// import icon4 from "../../../../FILES/Images/icon4.png";
import H24SvgIcon from '../../../../COMPONENTS/SVG/Icons/H24SvgIcon';
import PrivacySvgIcon from '../../../../COMPONENTS/SVG/Icons/PrivacySvgIcon';
import CostEffectivenessSvgIcon from '../../../../COMPONENTS/SVG/Icons/CostEffectivenessSvgIcon';
import AutomationSvgIcon from '../../../../COMPONENTS/SVG/Icons/AutomationSvgIcon';

import Reason from './Components/Reason';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import SectionCard from '../../../../COMPONENTS/UI/Cards/SectionCard';
import colors from '../../../../UTILS/colors';

function Reasons(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard  backgroundColor={colors.section} flexDirection={`column`} id={props.id}>
            <div className={classes.containerText}>                  
                <h1><span>{text[13]}</span> <span  style={{color:`rgb(66,82,253)`}}>{text[68]}</span></h1>
            </div>
            <div className={classes.containerAvantaje}>                  
                <Reason icon={H24SvgIcon} title={text[14]} text={text[15]} backColor={`rgb(233,242,244)`}/>
                <Reason icon={PrivacySvgIcon} title={text[16]} text={text[17]} backColor={`rgb(236,230,242)`}/>
                <Reason icon={CostEffectivenessSvgIcon} title={text[18]} text={text[19]} backColor={`rgb(236,231,224)`}/>
                <Reason icon={AutomationSvgIcon} title={text[20]} text={text[21]} backColor={`rgb(222, 244, 239)`}/>
            </div>
    </SectionCard> 

);
}


export default Reasons;
import IconSVG from '../IconSVG';


function VoiceCommandSvgIcon(props) {  
    // const color = props.c || 'black';
    const lineWidth=0.5;
    const svgWidth=32;
    const svgHeight=32;    
    const d1="M26,30H24V27H20a5.0055,5.0055,0,0,1-5-5V20.7207l-2.3162-.772a1,1,0,0,1-.5412-1.4631L15,13.7229V11a9.01,9.01,0,0,1,9-9h5V4H24a7.0078,7.0078,0,0,0-7,7v3a.9991.9991,0,0,1-.1426.5144l-2.3586,3.9312,1.8174.6057A1,1,0,0,1,17,20v2a3.0033,3.0033,0,0,0,3,3h5a1,1,0,0,1,1,1Z";
    const d2="M9.3325,25.2168a7.0007,7.0007,0,0,1,0-10.4341l1.334,1.49a5,5,0,0,0,0,7.4537Z";
    const d3="M6.3994,28.8008a11.0019,11.0019,0,0,1,0-17.6006L7.6,12.8a9.0009,9.0009,0,0,0,0,14.4014Z";
    const x="19";
    const y="12";
    const width="4";
    const height="2";

    const rect1={
        x:"19",
        y:"12",
        width:"4",
        height:"2"
    };

    


    return (
        <IconSVG 
        id={'voiceCommand'}
            svgWidth={svgWidth} 
            svgHeight={svgHeight} 
            lineWidth={lineWidth}        
            d1={d1} 
            d2={d2}
            d3={d3}
            rect1={rect1}
            x={x}
            y={y}
            width={width}
            height={height}
            className={props.className}
            style={{...props.style}}
        />
    );
};

export default VoiceCommandSvgIcon;
import IconSVG from '../IconSVG';

function AutomationSvgIcon(props) {  
    
    const lineWidth=1;
    const svgWidth=24;
    const svgHeight=24;
        
    const d1="M21 13.293V3h-8V1H8v2H3v4h1V4h4v2h5V4h7v9.293L17.707 11l-.707.707 3.5 3.5 3.5-3.5-.707-.707zM12 5H9V2h3zm8 15h-3v-2h-5v2H4v-9.293L6.293 13 7 12.293l-3.5-3.5-3.5 3.5.707.707L3 10.707V21h9v2h5v-2h4v-4h-1zm-4 2h-3v-3h3z";

    return (
        <IconSVG 
        id={"automation"}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            lineWidth={lineWidth}
            d1={d1} 
            
            className={props.className}
            style={{...props.style}}
        />
    );
};

export default AutomationSvgIcon;
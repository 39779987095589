import classes from './Articles.module.css';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import SectionCard from '../../../../COMPONENTS/UI/Cards/SectionCard';
import Article from './Article';
import articolPicture1 from "../../../../FILES/Images/Articol1.webp";
import articolPicture2 from "../../../../FILES/Images/Articol2.webp";
import articolPicture3 from "../../../../FILES/Images/Articol3.webp";

function Articles(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard flexDirection={`column`} id={props.id}>
            <div className={classes.containerText}>                  
                <h1><span>{text[36]}</span> <span  style={{color:`rgb(66,82,253)`}}>{text[68]}</span></h1>
            </div>
            <div className={classes.containerArticole}>                  
                <Article picture={articolPicture1} title={text[37]} text={text[38]} date={text[54]} linkPos={2}/>
                <Article picture={articolPicture2} title={text[40]} text={text[41]} date={text[55]} linkPos={3}/>
                <Article picture={articolPicture3} title={text[42]} text={text[43]} date={text[56]} linkPos={4}/>
            </div>
            
    </SectionCard> 

);
}


export default Articles;
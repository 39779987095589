const generalTextTranslations= {
    0: {
         en:``,
         ro:``
      },
    0: {
         en:``,
         ro:``
      },
    0: {
         en:``,
         ro:``
      },
    1: {
         en:`Home`,
         ro:`Acasă`
      },
    2: {
         en:`About Us`,
         ro:`Despre noi`
      },
    3: {
         en:`Solutions`,
         ro:`Soluții`
      },
    4: {
         en:`Functionalities`,
         ro:`Funcționalități`
      },
    5: {
         en:`Blog`,
         ro:`Blog`
      },
    6: {
         en:`Business account`,
         ro:`Cont bussiness`
      },
    7: {
         en:`Features`,
         ro:`Caracteristici`
      },
    8: {
         en:``,
         ro:``
      },
    9: {
         en:``,
         ro:``
      },
    10: {
         en:``,
         ro:``
      },
    11: {
         en:`The first Vocational Assistant for parents who want to raise talented and happy children`,
         ro:`Primul Asistent Vocațional pentru părinții care doresc să crească copii talentați și fericiți`
      },
    12: {
         en:`Give your child not only roots but also wings. Download the app that helps you discover your child's talents and turn their passions into achievements.`,
         ro:`Oferă-i copilului nu doar rădăcini, ci și aripi. Descarcă aplicația care te ajută sa descoperi talentele copilului și să transformi pasiunile lui în realizări.`
      },
    13: {
         en:`4 reasons to benefit from AI Vocational Assistant support`,
         ro:`4 motive pentru a beneficia de suportul Asistentului Vocațional AI`
      },
    14: {
         en:`Availability`,
         ro:`Disponibilitate`
      },
    15: {
         en:`Available 24/7, providing continuous access to emotional support and guidance.`,
         ro:`Este disponibil 24/7, oferind acces constant la suport emoțional și ghidare.`
      },
    16: {
         en:`Confidentiality`,
         ro:`Confidențialitate`
      },
    17: {
         en:`Parents can freely discuss with an AI psychologist without fear of judgment or exposing their vulnerabilities.`,
         ro:`Părinții pot discuta liber cu un psiholog AI fără frica de judecată sau de a-și expune vulnerabilitățile.`
      },
    18: {
         en:`Cost-effectiveness`,
         ro:`Costuri reduse`
      },
    19: {
         en:`More affordable counseling in managing specific situations for a greater number of parents.`,
         ro:`Consiliere mai accesibilă în gestionarea situațiilor specifice pentru un număr mai mare de părinți.`
      },
    20: {
         en:`Adaptability`,
         ro:`Adaptabilitate`
      },
    21: {
         en:`Allows constant customization of support based on the specific needs of each parent and child.`,
         ro:`Permite personalizarea suportului în mod constant  în funcție de nevoile specifice ale fiecărui părinte și copil.`
      },
    22: {
         en:`What does Parent's Advisor do?`,
         ro:`Ce face Parent's Advisor?`
      },
    23: {
         en:`All children are born geniuses! We are here to guide parents on a special journey of discovering their children's vocations. We use innovative technologies to identify and strengthen children's strengths, providing parents with a clear perspective on the unique potential of each child.`,
         ro:`Toți copii se nasc genii! Suntem aici pentru a ghida părinții într-o călătorie specială de descoperire a vocației copiilor. Folosim tehnologii inovatoare pentru a identifica și a consilida punctele forte ale copiilor, oferind părinților o perspectivă clară asupra potențialului unic al copilului.`
      },
    24: {
         en:`How can we help you?`,
         ro:`Cum te ajutăm?`
      },
    25: {
         en:`Step 1`,
         ro:`Pasul 1`
      },
    26: {
         en:`Psychological Tests`,
         ro:`Teste psihologice`
      },
    27: {
         en:`Identify your child's abilities and find out which extracurricular activities suit him.`,
         ro:`Identifică aptitudinile copilului și afla ce activități extrașcolare i se potrivesc.`
      },
    28: {
         en:`Step 2`,
         ro:`Pasul 2`
      },
    29: {
         en:`Nearby Locations`,
         ro:`Locații din proximitate`
      },
    30: {
         en:`Quickly and easily connect with educational centers where your child's talents can be developed.`,
         ro:`Conectează-te rapid și ușor cu centrele educaționale unde talentele copilului tău pot fi dezvoltate.`
      },
    31: {
         en:`Step 3`,
         ro:`Pasul 3`
      },
    32: {
         en:`Vocational Assistant`,
         ro:`Asistent Vocațional`
      },
    33: {
         en:`Communicate with your child's AI counselor whenever you need guidance and support.`,
         ro:`Comunică cu propriul consilier AI al copilului tău oricând ai nevoie de ghidare și suport.`
      },
    34: {
         en:`Get acquainted with the Parent's Advisor app`,
         ro:`Fă cunoștință cu aplicația Parent´s Advisor`
      },
    35: {
         en:`Explore more features designed to make your life as a parent easier.`,
         ro:`Descoperă mai multe funcționalități menite să-ți facă viața de părinte mai ușoară.`
      },
    36: {
         en:`Useful articles for stress-free parenting`,
         ro:`Articole utile pentru parenting fără stres`
      },
    37: {
         en:``,
         ro:`Ce activități sunt potrivite pentru un copil timid?`
      },
    38: {
         en:``,
         ro:`Copilul meu este mai retras și preferă să se joace singur. Are nevoie de ceva timp ca să se obișnuiască dacă venim într-un loc necunoscut.`
      },
    39: {
         en:`read more`,
         ro:`citește mai mult`
      },
    40: {
         en:``,
         ro:`Cum alegi activitățile extrașcolare pentru copilul tău?`
      },
    41: {
         en:``,
         ro:`Un studiu recent afirmă că 80% din copii își petrec timpul liber în fața calculatorului sau televizorului!`
      },
    42: {
         en:``,
         ro:`TOP 10 beneficii ale dansului pentru dezvoltarea copiilor`
      },
    43: {
         en:``,
         ro:`Mai ții minte când copilul tău a început să danseze? Cel mai probabil, atunci când nu știa nici măcar să meargă.`
      },
    44: {
         en:`Do you have a location or provide services for children? Get closer to your customers and benefit from a strong presence in the Parent's Advisor community in your area.`,
         ro:`Ai o locație sau furnizezi servicii pentru copii? Fii mai aproape de clienții tăi și beneficiază de o prezență consolidată în comunitatea părinților Parent's Advisor din zona ta.`
      },
    45: {
         en:`Other Features`,
         ro:`Alte caracteristici`
      },
    46: {
         en:`Short Videos`,
         ro:`Video-uri Scurte`
      },
    47: {
         en:`Provides visual presentations of various locations and activities, facilitating understanding and exploration of available options.`,
         ro:`Oferă prezentări vizuale ale diferitelor locații și activități, facilitând înțelegerea și explorarea opțiunilor disponibile.`
      },
    48: {
         en:`Reviews and Recommendations`,
         ro:`Recenzii și Recomandări`
      },
    49: {
         en:`Allows parents to access real perspectives from other parents, contributing to decision-making based on authentic experiences.`,
         ro:`Permite părinților să acceseze perspective reale ale altor părinți, contribuind la luarea deciziilor bazate pe experiențe autentice.`
      },
    50: {
         en:`Voice Assistance`,
         ro:`Asistența Vocală`
      },
    51: {
         en:`Delivers vocal support, enabling parents to interact with the application and receive information using voice commands.`,
         ro:`Furnizează suport vocal, permitând părinților să interacționeze cu aplicația și să primească informații folosind comenzi vocale.`
      },
    52: {
         en:`Personal Library`,
         ro:`Bibliotecă Personală`
      },
    53: {
         en:`Allows saving and organizing suggestions and recommendations in a personal library, for easy and quick access in the future.`,
         ro:`Permite salvarea și organizarea sugestiilor și recomandărilor într-o bibliotecă personală, pentru acces ușor și rapid în viitor.`
      },
    54: {
         en:`20 August 2022`,
         ro:`20 August 2022`
      },
    55: {
         en:`1 October 2022`,
         ro:`1 Octombrie 2022`
      },
    56: {
         en:`10 December 2022`,
         ro:`10 Decembrie 2022`
      },
    57: {
         en:``,
         ro:``
      },
    58: {
         en:``,
         ro:``
      },
    59: {
         en:``,
         ro:``
      },
    60: {
         en:``,
         ro:``
      },
    61: {
         en:``,
         ro:``
      },
    62: {
         en:``,
         ro:``
      },
    63: {
         en:``,
         ro:``
      },
    64: {
         en:``,
         ro:``
      },
    65: {
         en:``,
         ro:``
      },
    66: {
         en:``,
         ro:``
      },
};
export default generalTextTranslations;
import React from 'react';

import classes from './ButtonEmptyWithBorder.module.scss';

const ButtonEmptyWithBorder = (props) => {
  return (
    <button
      className={classes.button}
      type={props.type || 'button'}
      onClick={props.onClick}
      style={{backgroundColor: props.backgroundColor}} 
    >{props.text}
      {props.children}
    </button>
  );
};

export default ButtonEmptyWithBorder;

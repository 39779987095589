// import {useNavigate } from 'react-router-dom';
import classes from './TopSimpleNavigationMenu.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { storeGeneral_Actions } from '../../../../STORE/storeGeneral';
import NavOptions from './NavOptions';

function TopNavigationMenu (props) {
    const activeLang = useSelector((state) => state.generalStore.selectedLanguage); 
    const dispatch = useDispatch();    
    // const navigate = useNavigate();

    const onChangeLang_Handler = (lang) =>{
        dispatch( storeGeneral_Actions.changeLang({lang:lang}));        
        // navigate('/');
    };

return (
    <div className={classes.navListContainer}>
        <ul className={classes.ulMainNav} > 
            <NavOptions className={`${classes.liMainNav} ${classes.hideTextOnMobile}`} onClose={props.onClose}/>
            <li className={classes.liMainNav}>
                
                <span className={activeLang === 'en' ? classes.langActiveColor :''}  onClick={() => onChangeLang_Handler('en')}>ENG </span>
                <span> / </span>
                <span className={activeLang === 'ro' ? classes.langActiveColor : ''}  onClick={() => onChangeLang_Handler('ro')}> RO</span>
            </li>
        </ul>     
    </div>
);
};

export default TopNavigationMenu;

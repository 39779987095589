import { Fragment } from 'react';
import classes from './DropNavigationMenu.module.scss';
import NavOptions from './NavOptions';

function DropNavigationMenu (props) { 

return (
    <Fragment>    
        <div className={classes.backdrop} onClick={props.onClose}/>
        <div className={`${classes.modal} ${window.scrollY > 10 ? classes.active:''}`}>
            <ul className={classes.ulNav}>
               <NavOptions className={classes.liMobileNav}  onClose={props.onClose}/>
            </ul>
        </div>
    </Fragment>
);
};

export default DropNavigationMenu;

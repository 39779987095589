import classes from './Blog.module.scss';
import articolPicture2 from "../../../FILES/Images/Articol2.webp";
// import articolPicture3 from "../../../FILES/Images/Articol3.webp";

import PIS from '../../../COMPONENTS/UI/Texts/PIS';
import PB from '../../../COMPONENTS/UI/Texts/PB';
import PI from '../../../COMPONENTS/UI/Texts/PI';


import useGetTranslatedText from '../../../HOOKS/useGetTranslatedText';

function BlogCumAlegi(props) {
    const text = useGetTranslatedText('blog2_TextTranslations.js');
  return (      
        <div className={classes.mainContainer}>
            <div className={classes.topContainer}>
                <div className={classes.titleContainer}>
                    <h1>{text[1]}</h1>
                </div>
                <div className={classes.pictureContainer}>
                    <img className={classes.img} src={articolPicture2} alt={'parents advisor application'}/>    
                </div>  
            </div>

            <div className={classes.textContainer}>
                <PIS t={text[2]}/>
                <PB t={text[3]}/>
                    <PIS t={text[4]}/>
                <PB t={text[5]}/> 
                    <PIS t={text[7]}/>
                <PB t={text[8]}/>
                    <PIS t={text[9]}/>
                <PB t={text[10]}/>
                    <PIS t={text[11]}/>
                <PI t={text[12]}/>

                
            </div>
                                                                                                       
         </div>
  );
}

export default BlogCumAlegi;

import classes from './AboutUs.module.scss';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import SectionCard from '../../../../COMPONENTS/UI/Cards/SectionCard';
import telefonImg from "../../../../FILES/Images/Gestionarea.webp";
import ButtonEmptyWithBorder from '../../../../COMPONENTS/UI/Buttons/ButtonEmptyWithBorder';
import colors from '../../../../UTILS/colors';

function AboutUs(props) {
    const text = useGetTranslatedText('homePage_TextTranslations.js');
    const textGeneral = useGetTranslatedText('general_TextTranslations.js');
    
    return (
        <SectionCard backgroundColor={colors.section} textAlign={`left`} id={props.id}>
           <div className={classes.pictureContainer}>
                <img className={classes.img} src={telefonImg} alt={'parents advisor application'}/>    
            </div>
            <div className={classes.textContainer}>
                <h1>{text[22]}</h1>
                <p>{text[23]}</p>
                <div className={classes.buttons}>

                    <ButtonEmptyWithBorder text={textGeneral[18]}/>                
                 </div>
                
            </div>
        </SectionCard>
);
}
  
  export default AboutUs;

import classes from './LogoFooter.module.css';
import { scrollToSection } from "../../../../UTILS/scrollToSection";

function LogoFooter () {
  
    const onClick_Handler = (section) => {
        scrollToSection(section);
    }
    return (
        <div className={classes.logoContainer}>
            <div className={classes.logo}  onClick={() =>onClick_Handler('assistant')}/>
        </div>
    );
};

export default LogoFooter;

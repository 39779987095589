//https://www.sarasoueidan.com/blog/css-svg-clipping/
//https://www.svgrepo.com/
import classes from './IconSVG.module.css';

function IconSVG (props){    
     const {id, svgWidth, svgHeight, lineWidth, d1, d2, d3, d4, d5, d6, d7, d8, d9, cx, cy, r, x, y, width, height} = props;

return (
    <svg className={`${classes.animation} ${props.className}`} 
        style={{...props.style}}
        //  style={{width: props.width, height:props.height, filter:`${props.shadow ? 'drop-shadow(0px 10px 5px rgba(0,0,0,0.5))' : 'none'}`}}         
         viewBox={`0 0 ${svgWidth} ${svgHeight}`}         
        //  fill={fill}
         xmlns="http://www.w3.org/2000/svg">            
            <mask id={id} style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse"  >
                <path d={d1} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d2} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d3} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d4} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>                
                <path d={d5} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d6} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d7} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d8} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d9} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <circle cx={cx} cy={cy} r={r}/>
                {/* <rect x={rect1.x} y={rect1.y} width={rect1.width} height={rect1.height}/> */}
                <rect x={x} y={y} width={width} height={height}/>
            </mask>            
            <g mask={`url(#${id})`}>
                <rect width={svgWidth} height={svgHeight} />{/* fill={color} */}
            </g>            
    </svg>        
);
};

export default IconSVG;


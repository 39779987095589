import classes from './SocialMedia.module.scss';
import SectionCard from '../../../../COMPONENTS/UI/Cards/SectionCard';
import LogoFooter from './LogoFooter';
import Facebook2SvgIcon from '../../../../COMPONENTS/SVG/Icons/Facebook2SvgIcon';
import Instagram2SvgIcon from '../../../../COMPONENTS/SVG/Icons/Instagram2SvgIcon';

//https://www.facebook.com/profile.php?id=100065589875406
//https://www.instagram.com/parents_advisor/?igshid=YmMyMTA2M2Y%3D&fbclid=IwAR1E8GguOVPLUg1VUTI_Zq0B2URjgTk_DJxBf_G8a1wV1vLSEpEu8B62kBw

function SocialMedia() {
    const navigateTo_Handle = (socialMedia) => {
        switch (socialMedia) {
            case 'facebook':      
                // window.location.replace('https://www.facebook.com/profile.php?id=100065589875406');
                // window.location.assign('https://www.facebook.com/profile.php?id=100065589875406');
                window.open('https://www.facebook.com/profile.php?id=100065589875406', '_blank', 'noopener');                
                break;
            case 'instagram':      
                // window.location.replace('https://www.instagram.com/parents_advisor/');
                // window.location.assign('https://www.instagram.com/parents_advisor/');
                window.open('https://www.instagram.com/parents_advisor/', '_blank', 'noopener');
                break;
            default:
                break;                             
        }     
    };

    return (
        <SectionCard backgroundColor={'rgb(0, 5, 92)'} >
            <div className={classes.container}>
                <LogoFooter/>  
                <div className={classes.iconsContainer}>
                    <div className={classes.icon} onClick={()=>navigateTo_Handle('facebook')}>                  
                        <Facebook2SvgIcon className={`${classes.svgIcon}`} style={{width: `25px`}}/>
                    </div>
                    <div className={classes.icon} onClick={()=>navigateTo_Handle('instagram')}>
                        <Instagram2SvgIcon className={`${classes.svgIcon}`} style={{width: `20px`}}/>
                    </div>
                </div>
            </div>
        </SectionCard> 
    );
}

export default SocialMedia;


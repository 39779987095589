import IconSVG from '../IconSVG';


function PrivacySvgIcon(props) {  
    // const color = props.c || 'black';
    const lineWidth=0;
    const svgWidth=512;
    const svgHeight=512;    
    const d1="M510.831,167.648c-2.954-20.666-2.954-35.432-14.767-26.572c-11.81,8.86-84.65,27.247-162.402,35.433\n" +
    "c-36.479,3.844-61.048,14.564-77.663,27.262c-16.616-12.697-41.18-23.418-77.663-27.262\n" +
    "c-77.752-8.186-150.594-26.573-162.403-35.433C4.12,132.216,4.12,146.982,1.17,167.648c-5.43,38.006,5.902,144.691,79.724,183.076\n" +
    "c77.892,40.502,132.874,17.712,159.45,2.954c5.852-3.256,11.003-6.581,15.654-9.891c4.651,3.31,9.801,6.635,15.654,9.891\n" +
    "c26.577,14.758,81.562,37.548,159.454-2.954C504.924,312.339,516.257,205.654,510.831,167.648z M159.24,306.471\n" +
    "c-33.266-2.566-66.535-35.836-66.535-63.981c0,0,51.184,2.557,74.213,5.116c23.03,2.558,40.948,20.472,40.948,30.711\n" +
    "C207.865,288.549,192.509,309.021,159.24,306.471z M352.757,306.471c-33.266,2.55-48.622-17.922-48.622-28.154\n" +
    "c0-10.239,17.914-28.154,40.944-30.711c23.03-2.558,74.213-5.116,74.213-5.116C419.292,270.635,386.027,303.905,352.757,306.471z";


    return (
        <IconSVG 
        id={'privacy'}
            svgWidth={svgWidth} 
            svgHeight={svgHeight} 
            lineWidth={lineWidth}        
            d1={d1}             
            className={props.className}
            style={{...props.style}}
        />
    );
};

export default PrivacySvgIcon;
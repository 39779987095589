import React, {useState, useEffect, Fragment} from 'react';

import classes from './NavigationMenu.module.css';
import Logo from './components/Logo';
import DropButton from './components/DropButton';
import DropNavigationMenu from './components/DropNavigationMenu';
import TopSimpleNavigationMenu from './components/TopSimpleNavigationMenu';
import HomeNavOption from './components/HomeNavOption';

function NavigationMenu (props) {    
    const [showSideMenu, setShowSideMenu] = useState(false);

    const onCloseMenu_Handler = () => { setShowSideMenu(false); };    
    const menuIsOpened_Handler = (isOpen) => { setShowSideMenu(isOpen); }
    const [navMenuClass, setNavMenuClass] = useState();

    useEffect(() => {    
        const onScroll = () => {                   
            if (window.pageYOffset < 49) {setNavMenuClass(); }
            if (window.pageYOffset > 50){setNavMenuClass(classes.shadow); }
        };
        window.removeEventListener('scroll', onScroll);      
        window.addEventListener("scroll", onScroll, {passive: true} );
        return () => window.removeEventListener('scroll', onScroll);    
    }, []);

return (<Fragment> 
        <nav className={`${classes.navigation} ${navMenuClass}`}>         
            <div className={classes.navContainer}>                
                <Logo/>
                {props.page==='home' && <TopSimpleNavigationMenu onClose={onCloseMenu_Handler}/>}
                {(props.page==='terms' || props.page==='blog') && <HomeNavOption/>}
            </div>
            <div className={`${classes.backDiv} ${classes.showOnMobile}}`}/>                
        </nav>
        {showSideMenu && props.page==='home' && <DropNavigationMenu onClose={onCloseMenu_Handler}/>}
        {props.page==='home' && <DropButton menuIsOpened={menuIsOpened_Handler} onClose={showSideMenu}/>}
    </Fragment> 
    );
};

export default React.memo(NavigationMenu);


import classes from './Step.module.css';

function Step(props){

return (
    <div className={classes.container}>
        <div className={classes.stepContainer} style={{backgroundColor:props.backColor}}>
            {props.step}
        </div>        
        <h2 className={classes.h2} >{props.title}</h2>
        <p className={classes.p}>{props.text}</p>    
    </div>
);
}

export default Step;    

const generalTextTranslations= {
   ////////////// - Footer - //////////////
   0: {
         en:`© 2022 Parents Advisor. All rights reserved`,
         ro:`© 2022 Parents Advisor. Toate drepturile rezervate`
      },
   1: {
         en:`Terms and conditions`,
         ro:`Termeni și condiții`
      },
   2: {
         en:`Privacy policy`,
         ro:`Politica de confidențialitate`
      },     
   3: {
         en:`Developed by`,
         ro:`Creat de`
      },
   ////////////// - Header - //////////////
   4: {
         en:``,
         ro:``
      },
   5: {
         en:`Product`,
         ro:`Produs`
      },
   6: {
         en:`Solution`,
         ro:`Soluția`
      },
   7: {
         en:`Pricing`,
         ro:`Prețuri`
      },
   8: {
         en:`Our vision`,
         ro:`Viziune`
      },                              
   9: {
         en:`Contact Us`,
         ro:`Contactează-ne`
      },      
   10:{
         en:`Get started`,
         ro:`Să începem`
      },        
///////////////// Login - SignUp  ///////////////////
   11:{
         en:`Register new user`,
         ro:`Inregistrare utilizator nou`
      },
   12:{
         en:`E-mail`,
         ro:`E-mail`
      },
   13:{
         en:`Password`,
         ro:`Parola`
      },
   14:{
         en:`Confirm Password`,
         ro:`Confirmă Parola`
      },
   15:{
         en:`Create account`,
         ro:`Creare cont`
      },  
   16:{
         en:`Signing up...`,
         ro:`Inregistrare în progres ...`
      },    
   17:{
         en:`You have successfully registered!`,
         ro:`Ați fost înregistrat cu succes!`
      },                          
   18:{
         en:`Read More`,
         ro:`Citește mai mult`
      },          
      
   };
   
   export default generalTextTranslations;
   

   
   

const linksTextTranslations= {
   0: {
         en:`terms-and-conditions`, 
         ro:`termeni-si-conditii`
      },
   1: {
         en:`privacy-policy`,
         ro:`politica-de-confidentialitate`
      },
   2: {
         en:``,
         ro:`ce-activitati-sunt-potrivite-pentru-un-copil-timid`
      },
   3: {
         en:``,
         ro:`cum-alegi-activitatile-extrascolare-pentru-copilul-tau`
      },
   4: {
         en:``,
         ro:`top-10-beneficii-ale-dansului-pentru-dezvoltarea-copiilor`
      },      
};
   
   export default linksTextTranslations;
   

   
   
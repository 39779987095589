import IconSVG from '../IconSVG';


function CostEffectivenessSvgIcon(props) {  
    // const color = props.c || 'black';
    const lineWidth=0;
    const svgWidth=256;
    const svgHeight=173;
        
    const d1="M128.253,56.864c15.186,0,27.432-12.247,27.432-27.432S143.536,2,128.253,2\n" +
	"c-15.186,0-27.432,12.247-27.432,27.432C100.918,44.716,113.165,56.864,128.253,56.864z M64.571,136.32h-49.28\n" +
	"c-15.969,0-16.851-24.395,0.294-24.395H58.3l24.493-36.054c7.25-9.895,15.48-14.598,27.138-14.598h36.544\n" +
	"c11.659,0,19.888,4.311,27.138,14.598l24.591,36.054h43.01c17.243,0,16.165,24.395,0.588,24.395h-49.28\n" +
	"c-3.919,0-8.622-1.372-11.365-5.584l-18.811-26.844l-0.098,67.209H94.844l-0.098-67.209l-18.811,26.844\n" +
	"C73.192,134.85,68.49,136.32,64.571,136.32z";
    const d2="M30.957,95.543C46.939,95.543,60,82.568,60,66.5S47.025,37.457,30.957,37.457S2,50.518,2,66.586\n" +
	"C2,82.568,14.975,95.543,30.957,95.543z M29.353,68.963c-5.327-1.862-8.65-4.554-8.65-9.337c0-4.411,2.921-7.877,8.278-8.965v-4.783\n" +
	"h4.01v4.554c3.322,0,5.728,0.687,7.504,1.633l-1.633,5.728c-1.318-0.544-3.466-1.461-6.416-1.461s-4.554,1.461-4.554,2.921\n" +
	"c0,2.005,1.862,2.778,5.872,4.411c5.471,2.005,8.02,4.783,8.02,9.337c0,4.554-2.778,8.02-8.65,9.337v4.554h-4.01v-4.411\n" +
	"c-3.466,0-6.96-0.917-8.65-1.862l1.461-5.872c1.862,0.917,4.783,2.005,7.877,2.005c3.322,0,4.955-1.461,4.955-3.466\n" +
	"S33.191,70.453,29.353,68.963z";
    const d3="M243.225,48.936l2.553-4.05l-6.041-3.808l-2.509,3.979c-2.886-1.409-6.032-2.365-9.344-2.776v-3.799h4.395v-7.141h-15.656\n" +
	"v7.141h4.12v3.772c-14.811,1.718-26.346,14.336-26.346,29.6c0,16.433,13.369,29.802,29.802,29.802S254,88.289,254,71.856\n" +
	"C254,62.65,249.804,54.407,243.225,48.936z M224.198,94.522c-12.498,0-22.666-10.168-22.666-22.666S211.7,49.19,224.198,49.19\n" +
	"s22.666,10.168,22.666,22.666S236.696,94.522,224.198,94.522z M242.716,71.719h-18.403V53.316\n" +
	"C234.456,53.364,242.607,61.59,242.716,71.719z";
	
    

    return (
        <IconSVG 
        id={"costEffectiveness"}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            lineWidth={lineWidth}
            d1={d1}
            d2={d2}
            d3={d3}


            className={props.className}
            style={{...props.style}}
        />
    );
};

export default CostEffectivenessSvgIcon;
import classes from './Features.module.scss';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import SectionCard from '../../../../COMPONENTS/UI/Cards/SectionCard';
import Feature from './components/Feature';
import sectionPicture from "../../../../FILES/Images/ChildrensRainbow.webp";
import VideoTimerSvgIcon from '../../../../COMPONENTS/SVG/Icons/VideoTimerSvgIcon';
import Review2SvgIcon from '../../../../COMPONENTS/SVG/Icons/Review2SvgIcon';
import VoiceCommandSvgIcon from '../../../../COMPONENTS/SVG/Icons/VoiceCommandSvgIcon';
import Library3SvgIcon from '../../../../COMPONENTS/SVG/Icons/Library3SvgIcon';

function Features(props) {
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard textAlign={`left`} id={props.id}>
        <div className={classes.pictureContainer}>
            <img className={classes.img} src={sectionPicture} alt={'parents advisor'}/>    
        </div>
        <div className={classes.textContainer}>
            <div className={classes.title}>
                <h1>{text[45]}</h1>
            </div>
            <div className={classes.itemsContainer}>
                <Feature icon={VideoTimerSvgIcon} titleText={text[46]} paragraphText={text[47]} classNameColor={classes.fillColor} />
                {/* iconSize={35} */}
                <Feature icon={Review2SvgIcon} titleText={text[48]} paragraphText={text[49]} classNameColor={classes.fillColor} iconSize={42} />
                <Feature icon={VoiceCommandSvgIcon} titleText={text[50]} paragraphText={text[51]} classNameColor={classes.fillColor}/>
                <Feature icon={Library3SvgIcon} titleText={text[52]} paragraphText={text[53]} classNameColor={classes.fillColor}/>
               
            </div>
        </div>
    </SectionCard>
);
}
  
  export default Features;
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./PAGES/HomePage/HomePage";
import { useState } from "react";
// import PrivacyPolicy from "./PAGES/privacy-policy/Privacy";

import Terms from "./PAGES/Terms";
import Privacy from "./PAGES/Privacy";
import CeActivitatiSuntPotrivite from "./PAGES/Blogs/CeActivitatiSuntPotrivite";
import CumAlegi from "./PAGES/Blogs/CumAlegi";
import Top10Beneficii from "./PAGES/Blogs/Top10Beneficii";

// import Footer from './COMPONENTS/UI/Footer/Footer';
import NavigationMenu from "./COMPONENTS/UI/Navigation/NavigationMenu";

import useGetTranslatedText from './HOOKS/useGetTranslatedText'; 

function App() {    
    const link = useGetTranslatedText('links_TextTranslations.js');
    const [pageLoaded, setPageLoaded] = useState('home');
    const pageOnLoad_Handler = (pg) => {
        setPageLoaded(pg);
    }
    return (
        <BrowserRouter>
                <NavigationMenu page={pageLoaded}/>
                <Routes>
                    <Route path="/" index element={<HomePage onLoad={pageOnLoad_Handler}/>} />
                    <Route path="/terms-and-conditions" element={<Terms onLoad={pageOnLoad_Handler}/>} />
                    <Route path="/privacy-policy" element={<Privacy onLoad={pageOnLoad_Handler}/>} />
                    <Route path={link[2]} element={<CeActivitatiSuntPotrivite onLoad={pageOnLoad_Handler}/>} />
                    <Route path={link[3]} element={<CumAlegi onLoad={pageOnLoad_Handler}/>} />
                    <Route path={link[4]} element={<Top10Beneficii onLoad={pageOnLoad_Handler}/>} />
                    {/* <Route path={link[1]} element={<PrivacyPolicy/>} /> */}
                   
                </Routes>
        </BrowserRouter>
    );
}

export default App;

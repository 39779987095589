import classes from './Terms.module.css';
import Background from '../COMPONENTS/UI/Background/Background';

import Footer from '../COMPONENTS/UI/Footer/Footer';
import H3 from '../COMPONENTS/UI/Texts/TermsTextsElements/H3';
import P from '../COMPONENTS/UI/Texts/TermsTextsElements/P';
import PI from '../COMPONENTS/UI/Texts/TermsTextsElements/PI';
import PB from '../COMPONENTS/UI/Texts/TermsTextsElements/PB';
import PInos from '../COMPONENTS/UI/Texts/TermsTextsElements/PInos';
import S2 from '../COMPONENTS/UI/Texts/S2';
import { useEffect } from 'react';

function Terms(props) {
    
    useEffect(()=>{
        props.onLoad('terms');
    },[props])  
 
  return (
    <Background>        
        <div className={classes.container}>
            <S2/>
            <H3 t={1}/>
            <P t={2}/>
            <PB t={3}/>
            <PI t={4}/>
            <PB t={5}/>
            <PI t={6}/>
            
            <H3 t={7}/>
                <PB t={8}/>
                    <PI t={9}/>
                <PB t={10}/>
                    <PI t={11}/>
                <PB t={12}/>
                    <PI t={13}/>
                <PB t={14}/>
                    <PI t={15}/>   
                <PB t={16}/>
                    <PI t={17}/>
                <PB t={18}/>
                    <PI t={19}/>
                <PB t={20}/>
                    <PI t={21}/>

            <H3 t={22}/>
                <PB t={23}/>
                    <PI t={24}/>
                <PB t={25}/>
                    <PI t={26}/>
                <PB t={27}/>
                    <PI t={28}/>
                <PB t={29}/>
                    <PI t={30}/>   
                <PB t={31}/>
                    <PI t={32}/>
                <PB t={33}/>
                    <PI t={34}/>
                <PB t={35}/>
                    <PI t={36}/>                    

            <H3 t={37}/>
                <PI t={38}/>
                <PB t={39}/>
                    <PI t={40}/>
                <PB t={41}/>
                    <PI t={42}/>
                <PB t={43}/>
                    <PI t={44}/>
                <PB t={45}/>
                    <PI t={46}/>   
                <PB t={47}/>
                    <PI t={48}/>
                <PB t={49}/>
                    <PI t={50}/>
                <PB t={51}/>
                    <PI t={52}/>            

            <H3 t={53}/>
                <PB t={54}/>
                    <PI t={55}/>
                <PB t={56}/>
                    <PI t={57}/>
                <PB t={58}/>
                    <PI t={59}/>
                <PB t={60}/>
                    <PI t={61}/>   

            <H3 t={62}/>
                <PB t={63}/>
                    <PI t={64}/>
                <PB t={65}/>
                    <PI t={66}/>
                <PB t={67}/>
                    <PI t={68}/>
                <PB t={69}/>
                    <PI t={70}/>    

            <H3 t={71}/>
                <PI t={72}/>
                <PB t={73}/>
                    <PI t={74}/>

                    <P t={75}/>
                    <P t={76}/>  
                    <P t={77}/>  
                    <P t={78}/>  
                    <P t={79}/>  
                    <p>&nbsp;</p>

                <PB t={80}/>
                    <PI t={81}/>      
                <PB t={82}/>
                    <PI t={83}/>  
                <PB t={84}/>
                    <PInos t={85}/>  
                    <PInos t={86}/>
                    <PInos t={87}/>
                    <PInos t={88}/>
                    <PInos t={89}/>
                    <PInos t={90}/>
                    <PInos t={91}/>
                    <PInos t={92}/>
                    <PInos t={93}/>  
                    <PInos t={94}/>
                    <PInos t={95}/>
                    <PInos t={96}/>
                    <PInos t={97}/>
                    <PInos t={98}/>
                    <PInos t={99}/>
                    <PInos t={101}/>
                    <PInos t={102}/>
                    <PInos t={103}/>
                    <PInos t={104}/>                                                                                                                 
         </div>
         <Footer/>
    </Background>
  );
}

export default Terms;

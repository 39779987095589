import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import store from './STORE/index';


const container = document.getElementById('root')!;
const root = createRoot(container); 

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>    
);


reportWebVitals();

import classes from './Assistant.module.scss';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import SectionCard from '../../../../COMPONENTS/UI/Cards/SectionCard';
import telefonImg from "../../../../FILES/Images/Telefon.webp";
import appStoreImg from "../../../../FILES/Images/AppStore2.png";
import googlePlayImg from "../../../../FILES/Images/GooglePlay2.png";
import ButtonWithImg from '../../../../COMPONENTS/UI/Buttons/ButtonWithImg';


function Assistant(props) {
    const text = useGetTranslatedText('homePage_TextTranslations.js');
    
    const googlePlay_Handler = () => {
        window.open('https://play.google.com/store/apps/details?id=com.parentsadvisor&pcampaignid=web_share&fbclid=PAZXh0bgNhZW0CMTEAAaaSct7wSGeWpl4750r27fMdHz1lJjq0hkcdDU0cWoEqf0Ez1hSMzuwzUSg_aem_AebDnTrz4MRXWXuS6Zrb0zeVfjMMtK4EB_MDYbrCBnjkLzltq8RQxKmyBTy_BW5hrhQk4_JUNXTONTrFGSu9jLVS', '_blank', 'noopener');
    }
    const appStore_Handler = () => {
        window.open('https://apps.apple.com/ro/app/parents-advisor/id6479256862', '_blank', 'noopener');
    }
    
    return (
        <SectionCard textAlign={`left`} id={props.id}>
            <div className={classes.textContainer} >
                <h1>{text[11]}</h1>
                <p>{text[12]}</p>
                <div className={classes.buttons}>
                    <ButtonWithImg imgSrc={googlePlayImg} onClick={googlePlay_Handler}/>                
                    <ButtonWithImg imgSrc={appStoreImg} onClick={appStore_Handler}/>    
                </div>
                
            </div>

            <div className={classes.pictureContainer}>
                <img className={classes.img} src={telefonImg} alt={'parents advisor application'}/>    
            </div>
        </SectionCard>
);
}
  
  export default Assistant;
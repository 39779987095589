
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';

function P (props){
    const pageText = useGetTranslatedText('termsPage_TextTranslations.js');

    return (
        <p>{pageText[props.t]}</p>
    );
};

export default P;